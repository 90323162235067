import React, { useEffect } from "react";
import { Card, Row, Col, Rate, Button, Avatar, Typography } from "antd";
import { FormOutlined } from "@ant-design/icons";
import classes from './ReviewSection.module.scss'; // Import the SCSS module
import { Reviews } from "store/models/ReviewTypes";
import { useAppDispatch, useAppSelector } from "store/hook";
import { fetchReviews } from "store/reducers/reviewsReducers";

type Review = {
    username: string;
    date: string;
    rating: number;
    comment: string;
    avatar: string;
};

type Props = {
    rating: number;
    totalReviews: number;
    reviews: Reviews[];
};
function formatDate(isoString: string): string {
    const date = new Date(isoString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // месяцы начинаются с 0
    const day = String(date.getDate()).padStart(2, '0');

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
}

const ReviewSection: React.FC = () => {
    const { data } = useAppSelector((state) => state.rewues)
    const { productsDay } = useAppSelector((state) => state.produckt)
    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(fetchReviews({}))
    }, [])
    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <Typography.Title level={3} className={classes.rating}>
                    {data.length}
                </Typography.Title>
                <Rate disabled allowHalf defaultValue={data.length} className={classes.rate} />
                <Typography.Text className={classes.totalReviews}>({data.length} оценок)</Typography.Text>
       
            </div>
            <Row gutter={[16, 16]}>
                {data.slice(0, 6).map((review, index) => (
                    <Col key={index} xs={24} sm={24} md={12} lg={8}>
                        <Card className={classes.reviewCard}>
                            <Card.Meta
                                avatar={<Avatar src={review.disadvantages} />}
                                title={review.username}
                                description={formatDate(review.created_at)}
                            />
                            <Rate disabled defaultValue={review.stars} className={classes.reviewRate} />
                            <Typography.Paragraph className={classes.comment}>
                                {review.text}
                            </Typography.Paragraph>
                        </Card>
                    </Col>
                ))}
            </Row>
            {/* <Button type="primary" className={classes.showAllReviewsBtn}>
                Смотреть все отзывы
            </Button> */}
        </div>
    );
};

export default ReviewSection;
