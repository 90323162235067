
import { CancelToken } from "axios";
import { instance } from "./index";
import { OrderPlacing } from 'types/types';

const orders = (data: OrderPlacing, sourceToken?: CancelToken) =>
    instance.post('/billing/orders/', data, {
        cancelToken: sourceToken
    });

const endpoints = { orders };
export default endpoints;
