import React, { useEffect } from 'react';
import { Row, Col, Card, Typography, Tabs, TabsProps, Affix } from 'antd';
import styles from './Optovikam.module.scss';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { fetchReviews } from 'store/reducers/reviewsReducers';
import { fetchProductOfDay } from 'store/reducers/producRedusers';
import OfferSectionMini from 'Components/OfferSectionMini/OfferSectionMini';
import { fetchBanners } from 'store/reducers/BannerReducesr';
import axios from 'axios';
import { MainCaruselComponent } from 'Components';
import PartnersSection from 'Components/PatrnersSectin/PartnersSection';
import CustomerForm from 'Components/OptovikamForm/OptovikamForm';

const { Title, Paragraph } = Typography;


const Optovikam: React.FC = () => {
    const { productsDay } = useAppSelector((state) => state.produckt)
    const dispatch = useAppDispatch()

    const { data } = useAppSelector((state) => state.baner)

    useEffect(() => {
        const source = axios.CancelToken.source();
        dispatch(fetchBanners({ cancelToken: source.token, }))


        return () => {
            source.cancel('Запрос отменен, Слайдер приостоновлен');
        };
    }, [])
    useEffect(() => {
        dispatch(fetchProductOfDay({}))
    }, [])
    const top_product = productsDay?.top_products.slice(0, 2).map((item) => {
        return <OfferSectionMini
            id={item.id}
            category={item.category}
            title={item.title}
            currentPrice={item.price}
            oldPrice={item.old_price}
            discount="8%"
            imageUrl={item.image}
        />
    })
    return (
        <div className='mainContainer'>
            <div className={styles.container}>
                <div className={styles.mobnone}>
                    {data.slice(0, 2).map((item) =>
                        <Card style={{ background: `url(${item.image})center/cover`, marginBottom: '1rem', boxShadow: "100px -9px 100px 62px rgba(0, 0, 0, 0.75) inset", height: '400px' }}>
                            <h1>{item.title}</h1>
                            <p>{item.description}</p>
                            <a href={item.url}>перейти {'>'}</a>
                        </Card>)}
                </div>
                <div className={styles.desnnone}>
                    <MainCaruselComponent />
                </div>





                {/* <Card title={'Оптовикам'} style={{ width: '100%', maxWidth: '800px', margin: '0 auto' ,height:'fit-content'}}> */}
                <CustomerForm />
                {/* </Card> */}


            </div>
            <div className="mainContainer">

                <br /><br /><br /><br />
                <PartnersSection />
            </div></div>
    );
};

export default Optovikam;
