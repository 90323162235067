import { useEffect, useRef, useState } from "react";
import classes from "./PromotionCard.module.scss";
import { IPromotionCard } from "interfaces";
import { Row, Col, Typography, Button, Carousel, message, Descriptions, Flex, Tooltip, Rate, Card } from "antd";
import messageIcon from "../../assets/icon/message.svg";
import { numberWithSpaces } from "helpers";
import { StarFilled, HeartOutlined, ShoppingCartOutlined, StarOutlined, DeleteColumnOutlined, DeleteOutlined } from "@ant-design/icons";
import { CarouselRef } from "antd/es/carousel";
import { useNavigate } from "react-router-dom";
import { api } from "api";
import { getCookie } from "helpers/cookies";
import { useAppDispatch, useAppSelector } from "store/hook";
import { CartProduct } from "store/models/CartTypes";
import { addCartItem, addLocalCartItem, deleteCartItem, fetchCartItems, updateQuantityCartItem } from "store/reducers/cartRedusers";
import { getDiscount } from "helpers/getDiscount";
import Counter from "Components/Counter/Counter";
import axios from "axios";

const PromotionCard: React.FC<IPromotionCard> = (props) => {
  const {
    salesman_img,
    title,
    product_attributes,
    description,
    price,
    old_price,
    average_rating,
    image,
    review_count,
    product_images,
    id,
  } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { Title, Text } = Typography;
  const carouselRef = useRef<CarouselRef>(null);


  const [quantity, setQuantity] = useState<number>(1)
  const { data, status } = useAppSelector((state) => state.cart)
  const itemHaveInCard = data.cart_items.find(item => item.product.id === id)
  useEffect(() => {
    itemHaveInCard && setQuantity(itemHaveInCard.quantity)
  }, [itemHaveInCard])



  const source = axios.CancelToken.source();
  const cart_id = Number(localStorage.getItem('cart_id'));


  const add_item = async () => {

    try {
      const cart_id = Number(localStorage.getItem('cart_id'))
      await dispatch(addCartItem({ cart: cart_id, product_id: id, quantity: 1 })).then(() => {
        dispatch(fetchCartItems({ id: cart_id, cancelToken: source.token }));
      });
      message.open({
        type: "success",
        content: <div>Продукт добавлен в корзину, перейти в корзину</div>,
        onClick: () => navigate("/cart"),
      });

    }
    catch {
      message.error('Не получилось добавить в корзинку')
    }

  }

  function changeQuantity(action: string) {



    setQuantity(prev => {
      const newQuantity = action === '-' ? prev - 1 : prev + 1;
      itemHaveInCard && dispatch(updateQuantityCartItem({ id: itemHaveInCard.id, quantity: newQuantity })).then(() => {
        dispatch(fetchCartItems({ id: cart_id, cancelToken: source.token }));
      });
      return newQuantity;
    });
  }

  function deleteFromCart() {
    if (itemHaveInCard) {
      dispatch(deleteCartItem({ id: itemHaveInCard?.id })).then(() => {
        dispatch(fetchCartItems({ id: cart_id, cancelToken: source.token }));
      });
    }
  }


  const imgHover = (index: number) => {
    carouselRef.current?.goTo(index, true);
  };


  const height = 50
  const images = [{
    id: 0,
    image: image.includes('no_image') ? 'https://as1.ftcdn.net/v2/jpg/04/34/72/82/1000_F_434728286_OWQQvAFoXZLdGHlObozsolNeuSxhpr84.jpg' : image,
    product: 5,
  }, ...product_images]

  const [layout, setLayout] = useState<'horizontal' | 'vertical'>('horizontal');

  useEffect(() => {
    const updateLayout = () => {
      if (window.innerWidth <= 500) {
        setLayout('vertical');
      } else {
        setLayout('horizontal');
      }
    };

    updateLayout(); // Set initial layout
    window.addEventListener('resize', updateLayout);

    return () => {
      window.removeEventListener('resize', updateLayout);
    };
  }, []);
  return (
    <Card style={{ backgroundColor: '#fff !important' }} cover={<div onClick={() => navigate(`/product/${id}`)} className={classes.img_block}>
      {
        old_price ? <div onClick={() => navigate(`/product/${id}`)} className={classes.discount_block}>{`${getDiscount(old_price, price)}%`}</div>
          : ''
      }     <div onClick={() => navigate(`/product/${id}`)} className={classes.img_block_hover}>
        {images.map((item, index) => {
          return (
            <div
              onClick={() => navigate(`/product/${id}`)}
              className={classes.img_block_hover_it}
              onMouseMove={() => imgHover(index)}
              key={item.id}
            ></div>
          );
        })}
      </div>
      <Carousel  dotPosition={'top'} ref={carouselRef}>
        {images.map((item, index) => {
          return (
            <div  onClick={() => navigate(`/product/${id}`)} className={classes.img_block_item} key={index}>
              <img src={item.image} alt={title} />
            </div>
          );
        })}
      </Carousel>
    </div>} className={classes.promotionCard}>


      <div className={classes.contentSection}>
        <Title onClick={() => navigate(`/product/${id}`)} level={5} className={classes.title}>{title}</Title>
        <Rate  disabled defaultValue={average_rating} character={<StarOutlined />} className={classes.rating} />
        <Text onClick={() => navigate(`/product/${id}`)} className={classes.reviewCount}>({review_count} Отзывы)</Text>
        <div onClick={() => navigate(`/product/${id}`)} className={classes.priceSection}>
          <Text className={classes.price}>{price} KGS</Text>
          {old_price && <Text delete className={classes.oldPrice}>{old_price} KGS</Text>}
        </div>
        <div  onClick={() => navigate(`/product/${id}`)} className={classes.attributes}>
          {product_attributes.slice(0, 3).map((attr: any, idx: any) => (
            <Tooltip key={idx} title={attr.key}>
              <span className={classes.attribute}>{attr.value}</span>
            </Tooltip>
          ))}
        </div>
        <br />
        <Flex gap={5}>
          {
            itemHaveInCard ? <Button onClick={deleteFromCart} style={{ background: '#17193f' }} type="primary"><DeleteOutlined /></Button> :
              <Button
                type="primary"
                onClick={add_item}
                icon={<ShoppingCartOutlined />}
                className={classes.addToCartBtn}
              >
                В Корзинку
              </Button>
          }
          <div></div>
          {
            itemHaveInCard ?
              <div className='counter'>
                <Button style={{ background: '#17193f' }} type="primary" onClick={() => changeQuantity('+')} >+</Button>
                <Button >{quantity}</Button>
                <Button style={{ background: '#17193f' }} type="primary" disabled={quantity === 1 ? true : false} onClick={() => changeQuantity('-')} >-</Button>
              </div> :
              <div className='counter'>
                {/* <Button  type="primary" onClick={() => changeQuantity('+')} >+</Button>
                <Button >{quantity}</Button>
                <Button  type="primary" onClick={() => changeQuantity('-')} >-</Button> */}
              </div>
          }



        </Flex>
      </div>
    </ Card>
  );
};

export default PromotionCard;
