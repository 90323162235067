import React, { useEffect, useState } from "react";
import { Typography, Carousel } from "antd";
import { CaretLeftFilled, CaretRightFilled } from "@ant-design/icons";
import classes from "./Promotion.module.scss";
import { IPromotionCard } from "interfaces";
import { sliceText } from "helpers/sliceText";
import PromotionCard from "Components/NewPromoCard/PromotionCard";

const { Title, Text } = Typography;

interface IPromotion {
  title?: string;
  getCarts: () => any;
}

const ArrowLeft: React.FC<any> = ({ ...props }) => (
  <button {...props} className={classes.arrow} style={{ left: -20 }}>
    <CaretLeftFilled style={{ fontSize: 18 }} />
  </button>
);
const ArrowRight: React.FC<any> = ({ ...props }) => (
  <button {...props} className={classes.arrow} style={{ right: -20 }}>
    <CaretRightFilled style={{ fontSize: 18 }} />
  </button>
);

const NewPromotion: React.FC<IPromotion> = ({ title, getCarts }) => {
  const [cards, setCards] = useState([]);

  useEffect(() => {
    const fetchCards = async () => {
      const data = await getCarts();
      if (data.status === 200) setCards(data.data);
    };
    fetchCards();
  }, [getCarts]);

  return (
    <section className={classes.promotion}>
      <div className={classes.header}>
        <Title level={1}>{title || "Акции и скидки"}</Title>
        <Text>Все товары <CaretRightFilled /></Text>
      </div>
      <Carousel
        slidesToShow={2}
        arrows
        autoplay
        dots={false}
        responsive={[
          { breakpoint: 1200, settings: { slidesToShow: 2 } },
          { breakpoint: 992, settings: { slidesToShow: 2 } },
          { breakpoint: 768, settings: { slidesToShow: 1 } },
        ]}
      >
        {cards.map((item: IPromotionCard, index) => (
          <PromotionCard
            key={index}
            title={item.title}
            image={item.image}
            price={item.price}
            old_price={item.old_price}
            product_attributes={item.product_attributes}
            average_rating={item.average_rating}
            review_count={item.review_count}
            id={item.id}
          />
        ))}
      </Carousel>
    </section>
  );
};

export default NewPromotion;
