import { FC } from 'react'
import classes from './CategoriesSection.module.scss'
import { serviceData } from 'data/ServiceData/ServiceData'
import { Card } from 'antd'
import { useAppSelector } from 'store/hook'
import { useNavigate } from 'react-router-dom'
const CategoriesSectiom: FC = () => {
    const { data, children, status } = useAppSelector((state) => state.category)
    const navigate = useNavigate()
    return (
        <div className={classes.main} style={{ padding: '$layout' }}>

            <div className={classes.parent}>
                {data.map((section, index) => (
                    <div key={index} onClick={() => navigate(`/catalog/${section.id}`)} style={{ textAlign: 'center' }}>

                        <Card
                            hoverable
                            style={{ display: 'flex' }}
                            className={classes.dive}
                        >
                            <img src={section.icon} alt={section.icon} style={{ width: 70, height: 70 }} />
                        </Card>
                        <p >{section.title}</p>

                    </div>

                ))}
            </div>
        </div>
    )
}

export default CategoriesSectiom
