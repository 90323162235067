import React, { useEffect, useState } from 'react';
import classes from './CartComponent.module.scss'
import { Flex, Checkbox, Button } from 'antd';
import type { CheckboxProps } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import CartItemComponent from 'Components/CartItemComponent/CartItemComponent';
import { fetchCartItems, deleteCheckedCartItems, deleteCartItems } from 'store/reducers/cartRedusers';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hook';
import axios from 'axios';

type Props = {}

function formatNumber(number: number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

const CartComponent: React.FC<Props> = () => {
    const dispatch = useAppDispatch();
    const { data, status } = useAppSelector((state) => state.cart);
    const [is_all_check, setIsAllCheck] = useState(false);
    const [selectedItems, setSelectedItems] = useState<number[]>([]);
    const navigate = useNavigate();
    const source = axios.CancelToken.source();
    const cart_id = Number(localStorage.getItem('cart_id'));

    useEffect(() => {
        dispatch(fetchCartItems({ id: cart_id, cancelToken: source.token }));
    }, [cart_id]);

    const deleteCheckedItems = () => {
        let cart_items = data.cart_items.filter(item => selectedItems.includes(item.id));
        if (is_all_check) {
            dispatch(deleteCartItems({ id: data.id }))
        } else dispatch(deleteCheckedCartItems({ cart_items: cart_items }))
    }

    const onChange: CheckboxProps['onChange'] = (e) => {
        if (e.target.checked) {
            setSelectedItems(data.cart_items.map(item => item.id));
        } else {
            setSelectedItems([]);
        }
        setIsAllCheck(e.target.checked);
    };

    const handleItemSelect = (id: number, isSelected: boolean) => {
        setSelectedItems(prevSelectedItems => {
            if (isSelected) {
                return [...prevSelectedItems, id];
            } else {
                return prevSelectedItems.filter(itemId => itemId !== id);
            }
        });
    };

    const calculateTotalCost = () => {
        const total = data.cart_items
            .reduce((sum, item) => sum + item.total_price, 0);
        return total;
    };

    const total_cost = calculateTotalCost();
    return (
        <>
            {status === 'pending' ? <div>Loading...</div> : ''}
            {data.cart_items.length <= 0 && (
                <div className={classes.container}>
                    <Flex vertical justify='center' gap={20} align='center' className={classes.cart__empty}>
                        <h2>Корзина пуста</h2>
                        <p>Воспользуйтесь поиском, чтобы найти всё, что нужно.</p>
                        <Link to={'/'}>На главную</Link>
                    </Flex>
                </div>
            )}
            {data.cart_items.length > 0 && (
                <div className={classes.container}>
                    <Flex vertical gap={10} className={classes.cart__top}>
                        <Flex className={classes.cart__top1} align='center' gap={10}>
                            <h1>Корзина</h1>
                            <span>{data.cart_items.length === 1 ? '1 товар' : `${data.cart_items.length} товара`}</span>
                        </Flex>
                        <Flex className={classes.cart__top2} gap={10}>
                            <Checkbox onChange={onChange} checked={is_all_check}>Выбрать все</Checkbox>
                            <Flex gap={5} className={classes.delete__btn} align='center' onClick={deleteCheckedItems}><CloseOutlined />Удалить выбранные</Flex>
                        </Flex>
                    </Flex>
                    <Flex gap={20} className={classes.cart__bottom}>
                        <Flex vertical={true} className={classes.cart__items}>
                            {data.cart_items.map(e => (
                                <CartItemComponent
                                    key={e.id}
                                    cart_item={e}
                                    isSelected={selectedItems.includes(e.id)}
                                    onSelect={(isSelected) => handleItemSelect(e.id, isSelected)}
                                />
                            ))}
                        </Flex>
                        <Flex gap={30} vertical align='start' className={classes.cart__form}>
                            <h2>Детали заказа</h2>
                            <Flex vertical className={classes.form_data}>
                                <Flex>
                                    <span>Итого к оплате</span>
                                    <hr />
                                    <span>{formatNumber(total_cost)} с</span>
                                </Flex>
                            </Flex>
                            <Button onClick={() => navigate('/orderplasing')} style={{ color: "white" }} type='primary'>
                                Перейти к оформлению
                            </Button>
                        </Flex>
                    </Flex>
                </div>
            )}
        </>
    );
};

export default CartComponent;
