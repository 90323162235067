import React from 'react';
import classes from './OfferSectionMini.module.scss';
import { Flex } from 'antd';
import { Link, useNavigate } from 'react-router-dom';

interface OfferProps {
  title: string;
  currentPrice: any;
  oldPrice: any;
  discount: any;
  imageUrl: string;
  category: any;
  id: any;
}

const OfferSectionMini: React.FC<OfferProps> = ({ id, category, title, currentPrice, oldPrice, discount, imageUrl }) => {
  const navigate = useNavigate()
  return (
    <div className={classes.offerCard}>
      <div className={classes.offerCard_flex}>
        <div onClick={() => navigate(`/product/${id}`)} className={classes.offerCard__discount}>Скидка - {discount}</div>
        <div onClick={() => navigate(`/product/${id}`)} className={classes.offerCard__title}>{title}</div>
        <div onClick={() => navigate(`/product/${id}`)} className={classes.offerCard__price}>{currentPrice} с</div>
        <div onClick={() => navigate(`/product/${id}`)} className={classes.offerCard__oldPrice}>{oldPrice}</div>
        <Link to={`/catalog/${category}`} className={classes.offerCard__link}>Перейти в каталог</Link>
      </div>
      <div onClick={() => navigate(`/product/${id}`)} className={classes.offerCard__image}>
        <img src={imageUrl} alt={title} />
      </div>
    </div>
  );
};

export default OfferSectionMini;
