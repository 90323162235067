import React, { useEffect, useRef, useState } from 'react';
import { Typography, Button, Rate, Tooltip, Badge, Flex, message } from 'antd';
import { ShoppingCartOutlined, StarOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import classes from './PromotionCard.module.scss';
import { addCartItem, deleteCartItem, fetchCartItems, updateQuantityCartItem } from 'store/reducers/cartRedusers';
import axios from 'axios';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { CarouselRef } from 'antd/es/carousel';

interface IPromotionCard {
  title: string;
  product_attributes: { key: string; value: string }[];
  price: number;
  old_price?: number;
  average_rating: number;
  image: string;
  review_count: number;
  id: number;
}

const PromotionCard: React.FC<IPromotionCard> = ({ title, product_attributes, price, old_price, average_rating, image, review_count, id }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { Title, Text } = Typography;
  const carouselRef = useRef<CarouselRef>(null);


  const [quantity, setQuantity] = useState<number>(1)
  const { data, status } = useAppSelector((state) => state.cart)
  const itemHaveInCard = data.cart_items.find(item => item.product.id === id)
  useEffect(() => {
    itemHaveInCard && setQuantity(itemHaveInCard.quantity)
  }, [itemHaveInCard])



  const source = axios.CancelToken.source();
  const cart_id = Number(localStorage.getItem('cart_id'));


  const add_item = async () => {

    try {
      const cart_id = Number(localStorage.getItem('cart_id'))
      await dispatch(addCartItem({ cart: cart_id, product_id: id, quantity: 1 })).then(() => {
        dispatch(fetchCartItems({ id: cart_id, cancelToken: source.token }));
      });
      message.open({
        type: "success",
        content: <div>Продукт добавлен в корзину, перейти в корзину</div>,
        onClick: () => navigate("/cart"),
      });

    }
    catch {
      message.error('Не получилось добавить в корзинку')
    }

  }

  function changeQuantity(action: string) {



    setQuantity(prev => {
      const newQuantity = action === '-' ? prev - 1 : prev + 1;
      itemHaveInCard && dispatch(updateQuantityCartItem({ id: itemHaveInCard.id, quantity: newQuantity })).then(() => {
        dispatch(fetchCartItems({ id: cart_id, cancelToken: source.token }));
      });
      return newQuantity;
    });
  }

  function deleteFromCart() {
    if (itemHaveInCard) {
      dispatch(deleteCartItem({ id: itemHaveInCard?.id })).then(() => {
        dispatch(fetchCartItems({ id: cart_id, cancelToken: source.token }));
      });
    }
  }


  const imgHover = (index: number) => {
    carouselRef.current?.goTo(index, true);
  };


  const height = 50


  const [layout, setLayout] = useState<'horizontal' | 'vertical'>('horizontal');

  useEffect(() => {
    const updateLayout = () => {
      if (window.innerWidth <= 500) {
        setLayout('vertical');
      } else {
        setLayout('horizontal');
      }
    };

    updateLayout(); // Set initial layout
    window.addEventListener('resize', updateLayout);

    return () => {
      window.removeEventListener('resize', updateLayout);
    };
  }, []);
  return (
    <div className={classes.promotionCard}>
      <div onClick={() => navigate(`/product/${id}`)} className={classes.imageSection}>
        <img src={!image.includes('https') ? image : `https://arzanback.webtm.ru/${image}`} alt="product" className={classes.image} />
      </div>

      <div className={classes.contentSection}>
        <Title onClick={() => navigate(`/product/${id}`)} level={4} className={classes.title}>{title}</Title>
        <Rate disabled defaultValue={average_rating} character={<StarOutlined />} className={classes.rating} />
        <Text onClick={() => navigate(`/product/${id}`)} className={classes.reviewCount}>({review_count} reviews)</Text>
        <div onClick={() => navigate(`/product/${id}`)} className={classes.priceSection}>
          <Text className={classes.price}>{price} KGS</Text>
          {old_price && <Text delete className={classes.oldPrice}>{old_price} KGS</Text>}
        </div>
        <div onClick={() => navigate(`/product/${id}`)} className={classes.attributes}>
          {product_attributes?.slice(0, 3).map((attr, idx) => (
            <Tooltip key={idx} title={attr.key}>
              <span className={classes.attribute}>{attr.value}</span>
            </Tooltip>
          ))}
        </div>
        <br />
        <Flex gap={10}>
          {
            itemHaveInCard ? <Button onClick={deleteFromCart} style={{ background: '#17193f' }} type="primary">Удалить</Button> :
              <Button
                type="primary"
                onClick={add_item}
                icon={<ShoppingCartOutlined />}
                className={classes.addToCartBtn}
              >
                В Корзинку
              </Button>
          }
          <div></div>
          {
            itemHaveInCard ?
              <div className='counter'>
                <Button style={{ background: '#17193f' }} type="primary" onClick={() => changeQuantity('+')} >+</Button>
                <Button >{quantity}</Button>
                <Button style={{ background: '#17193f' }} disabled={quantity === 1 ? true : false} type="primary" onClick={() => changeQuantity('-')} >-</Button>
              </div> :
              <div className='counter'>
                <Button type="primary" onClick={() => changeQuantity('+')} >+</Button>
                <Button >{quantity}</Button>
                <Button type="primary" disabled={quantity === 1 ? true : false} onClick={() => changeQuantity('-')} >-</Button>
              </div>
          }


        </Flex>
      </div>
    </div>
  );
};

export default PromotionCard;
