import { Footer } from "antd/es/layout/layout"
import { FC, useEffect, useState } from "react"
import classes from './FooterComponent.module.scss'
import logo from '../../assets/icon/logo-removebg-preview.png'
import instagramLogo from "../../assets/icon/instagram.svg";
import telegramLogo from "../../assets/icon/telegram.svg";
import whatsappLogo from "../../assets/icon/whatsapp.svg";
import { Button, Flex, Form, Input } from "antd"
import { useAppDispatch, useAppSelector } from "store/hook"
import { Link, useNavigate } from "react-router-dom"
import { clearFilters, setFilters } from "store/slices/WindowSlice"
import { Categories } from "types/types"
import HeaderSceleton from "Components/Skeleton/HeaderSkeleton"
import { fetchCategoriesById, fetchFooterCategories } from "store/reducers/categoryReduser"
import { setHoveredItem } from "store/slices/categorySlice"
import { fetchSettings } from 'store/reducers/settingsReducers';
import axios from "axios"

import inst from '../../assets/Mask group-1.png'
import telega from '../../assets/Mask group.png'
import whats from '../../assets/socials-1.png'
import tiktok from '../../assets/socials.png'
const data = {
    categories: [
        {
            title: "Arzanall интернет-магазин",
            links: [
                { text: "О ARZANALL / About ARZANALL", url: "#" },
                { text: "Вакансии", url: "#" },
                { text: "Бренд BIGBEE", url: "#" },
                { text: "Реквизиты", url: "#" },
                { text: "Информация для СМИ", url: "#" }
            ]
        },
        {
            title: "Покупать как компания",
            links: [
                { text: "ARZANALL для бизнеса", url: "#" },
                { text: "Добавить компанию", url: "#" },
                { text: "Мои компании", url: "#" }
            ]
        },
        {
            title: "Помощь",
            links: [
                { text: "Как сделать заказ", url: "#" },
                { text: "Оплата", url: "#" },
                { text: "Доставка", url: "#" },
                { text: "Возврат товаров", url: "#" },
                { text: "Контакты", url: "#" },
                { text: "Безопасность", url: "#" },
                { text: "Горячая линия", url: "#" }
            ]
        }
    ]
};

const FooterComponent: FC = () => {
    const { footer } = useAppSelector((state) => state.category)
    const { settings } = useAppSelector((state) => state.window)
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    useEffect(() => {
        const source = axios.CancelToken.source();
        dispatch(fetchSettings({ cancelToken: source.token, }))
        dispatch(fetchFooterCategories({}))

    }, [])

    return (
        <Footer className={classes.footer}>
            <div className={classes.wrapper}>
                <div className={classes.footer_left}>
                    <div>
                        <img src={logo} height={63} alt="" />
                    </div>
                    <h1 className={classes.title}> Узнавайте первыми об акциях</h1>
                    <Form

                        name="footerForm"
                        className={classes.footer_form}
                    >


                        <Form.Item

                            name="input"
                            rules={[{ required: true, message: 'Please input something!' }]}
                        >
                            <input className={classes.input} placeholder="Email" style={{ borderRadius: '5px', background: '#000', color: '#fff' }} />
                        </Form.Item>

                        <Form.Item >
                            <Button className={classes.button}>Отправить</Button>
                        </Form.Item>
                    </Form>
                    {/* <h2>Нажим  я на кнопку, я соглашаюсь на обработку персональных данных</h2> */}
                </div>
                <div></div>
                <div className={classes.footer_navigation}>

                    {footer?.map((item: any) => (
                        <div key={item.id} >
                            <h1 onClick={() => {
                                dispatch(clearFilters({ id: Number(item.id) }));
                                dispatch(setFilters({ category: item.id }));
                                navigate(`/catalog/${item.id}`);
                            }}>
                                {item.title}
                            </h1>
                            <div>
                                {item.subcategories.map((el: any) => (
                                    <p onClick={() => {
                                        dispatch(clearFilters({ id: Number(item.id) }));
                                        dispatch(setFilters({ category: el.id }));
                                        navigate(`/catalog/${el.id}`);
                                    }} key={el.id}>
                                        {el.title}
                                    </p>
                                ))}
                            </div>
                        </div>
                    ))}

                </div>
                <div className={classes.footer_bottom_SM}>

                    <a href={settings.tiktok}> <li><img src={whats} style={{ objectFit: 'cover' }} height={50} width={30} alt="" /></li></a>
                    <a href={settings.whatsapp}>     <li><img src={telega} width={30} alt="" /></li></a>
                    <a href={settings.telegram}>     <li><img src={inst} width={30} alt="" /></li></a>
                    <a href={settings.instagram}>       <li><img style={{ objectFit: 'cover' }} height={50} src={tiktok} width={30} alt="" /></li></a>

                </div>
            </div>
            <footer className={classes.footer_bottom}>

                <div className={classes.flex_justify}>
                    <p>© 2024 ООО Arzanall. Все права защищены.</p>
                    <div className={classes.flex}>
                        <Link to={'/uslovia'}>Условия обслуживания</Link>
                        <Link to={'/confiditional'}> Политика конфиденциальности</Link>
                        <a href="https://www.instagram.com/codex_kg/"><p>Made  by Codex</p></a>
                    </div>
                </div>
            </footer>


        </Footer >
    )
}
export default FooterComponent