import React from 'react';
import { Table, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';

interface ProductAttributes {
    id: number;
    key: string;
    value: string;
}

interface Product {
    id: number;
    shop: number;
    title: string;
    description: string;
    image: string;
    price: number;
    product_attributes: ProductAttributes[];
    currency: string;
    product_code: string;
    created: string;
}

interface ProductConfigurationDetails {
    configurator_key: string;
    configurator_name: string;
    selected_value: string;
    price: number;
}

interface CartItem {
    id: number;
    cart: number;
    product: Product;
    quantity: number;
    is_selected: boolean;
    product_configuration: Record<string, string>;
    product_configuration_details: ProductConfigurationDetails[];
    total_price: number;
}

const dataSource: CartItem[] = [
    {
        id: 145,
        cart: 42,
        product: {
            id: 33,
            shop: 1,
            title: 'Доска обрезная нестроганая 25х100х6000 мм сорт 1-2 хвойные породы деловая экспортная',
            description: 'Предназначена для использования во многих видах строительных и ремонтных работ...',
            image: 'http://arzanback.webtm.ru/media/product_images/original-600x600-fit_3.webp',
            price: 449,
            product_attributes: [
                { id: 246, key: '123', value: '123' },
                { id: 238, key: 'Размер', value: '10м' },
            ],
            currency: 'KGS',
            product_code: '4351365824',
            created: '2024-08-22T14:25:21.523602+06:00',
        },
        quantity: 4,
        is_selected: false,
        product_configuration: { ves: '10кг', Размеры: '50cm' },
        product_configuration_details: [
            { configurator_key: 'ves', configurator_name: 'вес', selected_value: '10кг', price: 10 },
            { configurator_key: 'Размеры', configurator_name: 'Размеры', selected_value: '50cm', price: 4000 },
        ],
        total_price: 17836,
    },
    // Добавьте остальные элементы из вашего списка сюда
];

const columns: ColumnsType<CartItem> = [
    {
        title: 'Продукт',
        dataIndex: 'product',
        key: 'product',
        render: (product: Product) => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src={product.image} alt={product.title} style={{ width: '90px', marginRight: '10px' }} />
                <span
                    style={{
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 3,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxHeight: '3.6em', // 3 строки текста
                        lineHeight: '1.2em',
                        maxWidth: '200px', // Ограничение ширины блока
                    }}
                >
                    {product.title}
                </span>
            </div>
        ),
    },
    {
        title: 'Конфигурация',
        dataIndex: 'product_configuration_details',
        key: 'product_configuration_details',
        render: (details: ProductConfigurationDetails[]) => (
            <>
                {details.map((detail) => (
                    <Tag key={detail.configurator_key}>
                        {detail.configurator_name}: {detail.selected_value} (+{detail.price} KGS)
                    </Tag>
                ))}
            </>
        ),
    },
    {
        title: 'Количество',
        dataIndex: 'quantity',
        key: 'quantity',
    },
    {
        title: 'Цена',
        dataIndex: 'total_price',
        key: 'total_price',
        render: (price: number) => `${price} KGS`,
    },
];

const CartTable: React.FC<any> = ({ data }) => {
    const isMobile = window.innerWidth <= 767;

    if (isMobile) {
        return (
            <div>
                {data.map((item: any) => (
                    <div
                        key={item.id}
                        style={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr',
                            gap: '10px',
                            padding: '10px',
                            border: '1px solid #f0f0f0',
                            marginBottom: '10px',
                        }}
                    >
                        <div>
                            <strong>Продукт:</strong> <br />
                            <img src={item.product.image} alt={item.product.title} style={{ width: '100%' }} />
                            <div
                                style={{
                                    display: '-webkit-box',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: 3,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    maxHeight: '3.6em',
                                    lineHeight: '1.2em',
                                }}
                            >
                                {item.product.title}
                            </div>
                        </div>
                        <div>
                            <strong>Конфигурация:</strong> <br />
                            {item.product_configuration_details.map((detail: any) => (
                                <Tag key={detail.configurator_key}>
                                    {detail.configurator_name}: {detail.selected_value} (+{detail.price} KGS)
                                </Tag>
                            ))}
                            <br />
                            <strong>Количество:</strong> {item.quantity} <br />
                            <strong>Цена:</strong> {item.total_price} KGS
                        </div>
                    </div>
                ))}
            </div>
        );
    }

    return (
        <div style={{ overflowX: 'auto' }}>
            <Table
                dataSource={data}
                columns={columns}
                rowKey="id"
                pagination={false}
                scroll={{ x: 'max-content' }} // Добавляем горизонтальную прокрутку
            />
        </div>
    );
};

export default CartTable;
