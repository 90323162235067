import { Route, Routes } from 'react-router-dom';
import Main from 'routes/Main/Main';
import './scss/app.scss';
import MainPage from 'routes/MainPage/MainPage';
import { Login, OrderPlacing, SignUp } from 'Components';
import { Favorites, Cart } from 'routes';
// import SinglePageProduct from 'routes/SinglePageProduct/SinglePageProduct';
import { Suspense, lazy } from 'react';
import AboutUs from 'routes/AboutUs/AboutUs';
import ContactSection from 'routes/Contact/Contact';
import NotFound from 'Components/NOtFound/NotFound';
import Optovikam from 'routes/Optovikam/Optovikam';
import SectionsContainer from 'routes/MainPage/SectionContainer';
const Catalog = lazy(() => import('./routes/Catalog/Catalog'));
const SinglePageProduct = lazy(() => import('./routes/SinglePageProduct/SinglePageProduct'));
const NewsPage = lazy(() => import('./routes/NewsPage/NewsPage'));
const PoliticData = lazy(() => import('./routes/PoliticData/PolitikData'))
const Cofiditioanal = lazy(() => import('./routes/PoliticData/Confifditioanal'))
const Uslovia = lazy(() => import('./routes/PoliticData/Uslovia'))


function App() {

  return (
    <Routes>
      <Route path='/login' element={<Login />} />
      <Route path='/signUp' element={<SignUp />} />
      <Route path='/' element={<Main />}>
        <Route index element={<MainPage />} />
        <Route path='/edit/:token' element={<SectionsContainer />} />
        <Route path='/catalog/:id' element={<Suspense fallback={<div>Loading....</div>}>
          <Catalog />
        </Suspense>} />
        <Route path='/favorites' element={<Favorites />} />
        <Route path='/orderplasing' element={<OrderPlacing />} />
        <Route path='/product/:id' element={<Suspense fallback={<div>Loading...</div>}><SinglePageProduct /></Suspense>} />
        <Route path='/cart' element={<Cart />} />
        <Route path='/aboutus' element={<AboutUs />} />
        <Route path='/contact' element={<ContactSection />} />
        <Route path='/news' element={<Suspense fallback={<div>Loading...</div>}><NewsPage /></Suspense>} />

        <Route path='/politik' element={<Suspense fallback={<div>Loading...</div>}><PoliticData /></Suspense>} />
        <Route path='/uslovia' element={<Suspense fallback={<div>Loading...</div>}><Uslovia /></Suspense>} />
        <Route path='/confiditional' element={<Suspense fallback={<div>Loading...</div>}><Cofiditioanal /></Suspense>} />

        <Route path='/optovikam' element={<Optovikam />} />
      </Route>
      <Route path='*' element={<main className={'errorPage'}><NotFound /></main>} />
    </Routes>
  );
}

// const Authorization = ({ children }: any) => {
//   if (getCookie('access_token')) {


//     return <Navigate to='/' />
//   }

//   return children;
// }

export default App;