import React from 'react';
import { Card, Carousel, Flex } from 'antd';
import { serviceData } from 'data/ServiceData/ServiceData';
import classes from './ServiceComponent.module.scss'
import { Link } from 'react-router-dom';


const contentStyle: React.CSSProperties = {
    margin: 0,
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
};

const ServiceComponent: React.FC = () => {
    const onChange = (currentSlide: number) => {
        console.log(currentSlide);
    };
    return (
        <div style={{ padding: '$layout' }}>
            <div className={`${classes.parent} ${classes.mobBlock}`}>
                {serviceData.map((section, index) => (

                    <Card
                        key={index}
                        style={{ display: 'flex' }}
                        className={`${classes.div} ${classes[`div${index + 1}`]}`}
                        hoverable

                    >
                        <div className={classes.flex}>
                            <div>

                                <img src={section.icon} alt={section.icon} style={{ width: 70, height: 70, marginBottom: '1rem' }} />

                            </div>
                            <Card.Meta
                                title={section.title}
                                description={section.description}
                            />
                        </div>
                    </Card>

                ))}
            </div>
            <div className={classes.mobile}>
                <Flex justify='space-between'>
                    <h3>Наши сервисы</h3>
                    <Link to={'/'}>
                        Смотреть все
                    </Link>
                </Flex>
                <Carousel responsive={[
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 2,
                        },
                    },
                    {
                        breakpoint: 576,
                        settings: {
                            slidesToShow: 1.5,
                        },
                    },
                ]} slidesToShow={2} afterChange={onChange}>
                    {serviceData.map((section, index) => (

                        <Card
                            key={index}
                            className={classes.sliderItem}
                            hoverable

                        >

                            <div>

                                <img src={section.icon} alt={section.icon} style={{ width: 70, height: 70, marginBottom: '1rem' }} />

                            </div>
                            <Card.Meta
                                title={section.title}
                                description={section.description}
                            />

                        </Card>

                    ))}
                </Carousel>
            </div>

        </div>
    );
};

export default ServiceComponent;
