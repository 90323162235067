import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchFilterProducts, fetchProductById, fetchProductOfDay, fetchProducts, fetchProductsLive } from 'store/reducers/producRedusers';
import { ProductData, ProductPopular, SingleProduct } from 'types/types';

interface ProductsState {
    data: ProductData;
    live: ProductData;
    status: 'idle' | 'pending' | 'succeeded' | 'failed';
    productsDay: ProductPopular | null;
    selectedProduct: SingleProduct | null;
    error: string | null;
    laoding: boolean
}

const initialState: ProductsState = {
    data: {
        count: 0,
        next: '',
        previous: null,
        results: []
    },
    live: {
        count: 0,
        next: '',
        previous: null,
        results: []
    },
    status: 'idle',
    productsDay: null,
    selectedProduct: {
        average_rating: 4.5,
        brand: 1,
        similars: [],
        category: [10, 20],
        created: "2024-08-14T12:34:56Z",
        description: "This is a sample product description that provides details about the product.",
        id: 101,
        image: "https://example.com/product-image.jpg",
        length: null, // Assuming 'null' is the only value
        old_price: 99.99,
        price: 79.99,
        breadcrumbs: [
            [{ id: 1, title: "Home" }, { id: 2, title: "Electronics" }],
            [{ id: 3, title: "Smartphones" }]
        ],
        product_attributes: [
            { key: "Color", value: "Black" },
            { key: "Storage", value: "128GB" }
        ],
        product_delivery: [

        ],
        product_code: "SP-12345",
        product_configurator: [
            {
                id: 1,
                configurator_key: "Color",
                key: "color",
                values: [
                    { id: 1, title: "Black", price: 0 },
                    { id: 2, title: "White", price: 0 }
                ]
            },
            {
                id: 2,
                configurator_key: "Storage",
                key: "storage",
                values: [
                    { id: 1, title: "128GB", price: 20 },
                    { id: 2, title: "256GB", price: 50 }
                ]
            }
        ],
        product_images: [
            { id: 1, product: 101, image: "https://example.com/image1.jpg" },
            { id: 2, product: 101, image: "https://example.com/image2.jpg" }
        ],
        product_reviews: [
            {
                id: 1,
                user: 101,
                username: "john_doe",
                product: 101,
                text: "Great product, highly recommend!",
                stars: 5,
                advantages: "Fast, Reliable",
                count_dislike: 2,
                count_like: 10,
                created_at: "2024-08-13T10:00:00Z",
                disadvantages: "None",
                terms_of_use: "Review is based on personal experience."
            },
            {
                id: 2,
                user: 102,
                username: "jane_doe",
                product: 101,
                text: "Good value for money.",
                stars: 4,
                advantages: "Affordable, Good performance",
                count_dislike: 1,
                count_like: 8,
                created_at: "2024-08-12T14:20:00Z",
                disadvantages: "Battery life could be better"
            }
        ],
        review_count: 2,
        shop_logo: "https://example.com/shop-logo.jpg",
        shop_name: "Example Shop",
        title: "Sample Product",
        weight: null, // Assuming 'null' is the only value
        width: null // Assuming 'null' is the only value
    },
    error: null,
    laoding: false
};


const productsSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        clearProducts: (state,) => {
            state.data = {
                count: 0,
                next: '',
                previous: null,
                results: []
            };
        },
        replaceProducts: (state, action) => {
            state.data = action.payload;
        },
        setProductOfDay: (state, action: PayloadAction<ProductPopular>) => {
            state.productsDay = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchFilterProducts.pending, (state) => {
                state.status = 'pending';
                state.laoding = true
            })
            .addCase(fetchFilterProducts.fulfilled, (state, action: PayloadAction<ProductData>) => {
                state.status = 'succeeded';
                state.data = action.payload;
                state.laoding = false
            })
            .addCase(fetchFilterProducts.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error ? action.error.message || 'Failed to fetch products' : 'Failed to fetch products';
                state.laoding = false
            })


            .addCase(fetchProductsLive.pending, (state) => {
                state.status = 'pending';
                state.laoding = true
            })
            .addCase(fetchProductsLive.fulfilled, (state, action: PayloadAction<ProductData>) => {
                state.status = 'succeeded';
                state.live = action.payload;
                state.laoding = false
            })
            .addCase(fetchProductsLive.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error ? action.error.message || 'Failed to fetch products' : 'Failed to fetch products';
                state.laoding = false
            })



            .addCase(fetchProducts.pending, (state) => {
                state.status = 'pending';
                state.laoding = true
            })
            .addCase(fetchProducts.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = action.payload;
                state.laoding = false
            })
            .addCase(fetchProducts.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error ? action.error.message || 'Failed to fetch products' : 'Failed to fetch products';
                state.laoding = false
            })

            .addCase(fetchProductById.pending, (state) => {
                state.status = 'pending';
                state.laoding = true
            })
            .addCase(fetchProductById.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.selectedProduct = action.payload;
                state.laoding = false
            })
            .addCase(fetchProductById.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error ? action.error.message || 'Failed to fetch product' : 'Failed to fetch product';
                state.laoding = false
            })
            .addCase(fetchProductOfDay.pending, (state) => {
                state.status = 'pending';
                state.laoding = true;
            })
            .addCase(fetchProductOfDay.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.productsDay = action.payload;
                state.laoding = false;
            })
            .addCase(fetchProductOfDay.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error ? action.error.message || 'Failed to fetch product' : 'Failed to fetch product';
                state.laoding = false;
            });
    },
});

export const { clearProducts, replaceProducts, setProductOfDay } = productsSlice.actions;
export const selectProducts = (state: { products: ProductsState }) => state.products;

export default productsSlice.reducer;
