import React from 'react';
import icon1 from "../../assets/icon/Section2/SVG.svg";
import icon2 from "../../assets/icon/TopOffer/express-car.svg";
import icon3 from "../../assets/icon/Section2/Box.svg";
import icon4 from "../../assets/icon/Section2/SVG (1).svg";
import classes from "./Advantages.module.scss"
import { Carousel, Flex } from 'antd';

const Advantages: React.FC = () => {
    return (
        <div>
            <div className={`${classes.advantages} ${classes.nomob}`}>
                <div className={classes.advantage}>
                    <div className={classes.advantage_Image}>
                        <img src={icon1} alt="advantage" />
                    </div>
                    <div>
                        <h3>Большой ассортимент</h3>
                        <p>Обширный выбор строительных материалов для всех этапов строительства.
                        </p>
                    </div>

                </div>
                <div className={classes.advantage}>
                    <div className={classes.advantage_Image}>
                        <img src={icon2} alt="advantage" />
                    </div>
                    <div>
                        <h3>Доставка стройматериалов</h3>
                        <p>Быстрая и удобная доставка строительных материалов в течение 24 часов.
                        </p>
                    </div>
                </div>
                <div className={classes.advantage}>
                    <div className={classes.advantage_Image}>
                        <img src={icon3} alt="advantage" />
                    </div>
                    <div>
                        <h3>Простой возврат товаров</h3>
                        <p>Возврат строительных материалов в течение 30 дней без лишних вопросов.</p>
                    </div>

                </div>
                <div className={classes.advantage}>
                    <div className={classes.advantage_Image}>
                        <img src={icon4} alt="advantage" />
                    </div>
                    <div>
                        <h3>управления качеством</h3>
                        <p>Контроль качества строительных материалов по стандарту ISO 9001.</p>
                    </div>

                </div>
            </div>
            <div className={classes.mob}>
                <Carousel dots={false} className='mainContainer' slidesToShow={1}>
                    <Flex >
                        <Flex className={classes.advantage} gap={16}>
                            <div className={classes.advantage_Image}>
                                <img src={icon1} alt="advantage" />
                            </div>
                            <div>
                                <h3>Большой ассортимент</h3>
                                <p>строительных материалов
                                    всегда в наличии</p>
                            </div>
                        </Flex>


                    </Flex>
                    <Flex >
                        <Flex className={classes.advantage} gap={16}> <div className={classes.advantage_Image}>
                            <img src={icon2} alt="advantage" />
                        </div>
                            <div>
                                <h3>Доставка стройматериалов</h3>
                                <p>в течение четырех часов
                                    или в любое удобное время</p>
                            </div></Flex>

                    </Flex>
                    <Flex>
                        <Flex className={classes.advantage} gap={16}>
                            <div className={classes.advantage_Image}>
                                <img src={icon3} alt="advantage" />
                            </div>
                            <div>
                                <h3>Простой возврат товаров</h3>
                                <p>которые не пригодились
                                    в течение 360 дней</p>
                            </div>
                        </Flex>
                    </Flex>
                    <Flex>
                        <Flex className={classes.advantage} gap={16}>

                            <div className={classes.advantage_Image}>
                                <img src={icon4} alt="advantage" />
                            </div>
                            <div>
                                <h3>управления качеством</h3>
                                <p>по стандарту ISO 9001</p>
                            </div>

                        </Flex>
                    </Flex>
                </Carousel>
            </div>

        </div>
    );
};

export default Advantages;
