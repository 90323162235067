// Header.tsx
import React, { useEffect, useRef, useState } from 'react';
import { Badge, Button, Card, Divider, Drawer, Flex, FloatButton, Image, Popover, Skeleton } from 'antd';
import classes from './Header.module.scss'
import { AppstoreOutlined, CloseOutlined, CustomerServiceOutlined, LeftOutlined, MenuFoldOutlined, MessageOutlined, RightOutlined, ShoppingCartOutlined, UserOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from 'store/hook';
import axios from 'axios';
import { fetchCategories, fetchCategoriesById } from 'store/reducers/categoryReduser';
import { Categories } from 'types/types';
import { setHoveredItem } from 'store/slices/categorySlice';
import { Link, useNavigate } from 'react-router-dom';
import HeaderSceleton from 'Components/Skeleton/HeaderSkeleton';
import { clearFilters, setBredCrumps, setFilters } from 'store/slices/WindowSlice';
import logo from '../../assets/icon/logo-removebg-preview.png'
import { fetchSettings } from 'store/reducers/settingsReducers';
import { headerItems } from 'data/HeaderItems/HeaderItems';
import { fetchCartItems } from 'store/reducers/cartRedusers';
import { fetchProductsLive } from 'store/reducers/producRedusers';
import inst from '../../assets/Mask group-1.png'
import telega from '../../assets/Mask group.png'
import whats from '../../assets/socials-1.png'
import tiktok from '../../assets/socials.png'
import BidForm from 'Components/BidForm/BidForm';


const HeaderComponent: React.FC = () => {
    const { data, children, status } = useAppSelector((state) => state.category)
    const { data: cart } = useAppSelector((state) => state.cart)
    const source = axios.CancelToken.source();
    const [pop, setPop] = useState(false)
    const { settings } = useAppSelector((state) => state.window)
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const [open, setOpen] = useState(false);
    const [category, setCategory] = useState<number>(1)
    const [childrenDrawer, setChildrenDrawer] = useState(false);
    const [mobileOpen, setMobileOpen] = useState(false)
    const [isScrolled, setIsScrolled] = useState(false);

    const { live } = useAppSelector((state) => state.produckt)
    const [search, setSerach] = useState('')

    const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

    // Функция debounce без использования библиотек
    const debouncedFetchProducts = (value: string) => {
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        debounceTimeout.current = setTimeout(() => {
            dispatch(fetchProductsLive({ value }));
        }, 300); // 300 миллисекунд задержка
    };

    useEffect(() => {
        debouncedFetchProducts(search);

        // Очистка эффекта при размонтировании
        return () => {
            if (debounceTimeout.current) {
                clearTimeout(debounceTimeout.current);
            }
        };
    }, [search, dispatch]);


    const [visibleItems, setVisibleItems] = useState(5); // Изначально отображаем 5 элементов

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            switch (true) {
                case width < 926:
                    setVisibleItems(0);
                    break;
                case width < 1024:
                    setVisibleItems(2);
                    break;
                case width < 1200:
                    setVisibleItems(3);
                    break;
                case width < 1200:
                    setVisibleItems(3);
                    break;
                case width < 1461:
                    setVisibleItems(4);
                    break;
                default:
                    setVisibleItems(5);
                    break;
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    useEffect(() => {
        const source = axios.CancelToken.source();
        dispatch(fetchCategories({ cancelToken: source.token, }))
        dispatch(fetchSettings({ cancelToken: source.token, }))
        return () => {
            source.cancel('Запрос отменен, компонент размонтирован');
        };
    }, []);
    useEffect(() => {
        document.title = settings.title;
        const linkElement = document.createElement('link');
        linkElement.rel = 'stylesheet';
        linkElement.rel = 'icon'
        linkElement.href = settings.logo;
        document.head.appendChild(linkElement)
    }, [settings.logo]);

    const showDrawer = () => {


        setOpen(true)


    };
    const onClose = () => {
        setOpen(false);
    };
    useEffect(() => {
        document.body.style.overflow = open ? 'hidden' : 'auto';
        return () => { document.body.style.overflow = 'auto' };
    }, [open]);
    const showChildrenDrawer = () => {
        setChildrenDrawer(true);
    };
    const handleMouseEnter = (key: string) => {
        const source = axios.CancelToken.source();
        if (!children[category]) {


            dispatch(fetchCategoriesById({ cancelToken: source.token, id: +key })).then((res: any) => {
                dispatch(setHoveredItem(res.payload));
                // getBredCrumps(res.payload, 18)
            });
        }
        setCategory(+key);

    };

    const subCateoryList = {
        'succeeded': children[category]?.subcategories?.map((item: Categories) =>
            <div onClick={() => {
                dispatch(clearFilters({ id: Number(item.id) }))
                dispatch(setFilters({ category: item.id }))
                navigate(`/catalog/${item.id}`)
                onClose()
                onChildrenDrawerClose()
            }} className={classes.openCategories_main_items_item} key={item.id}>
                <Card className={classes.category_baner_item}>
                    <Image preview={false} width={'100%'} height={'100%'} src={item?.banner === null ? 'https://static.vecteezy.com/system/resources/thumbnails/022/014/063/small_2x/missing-picture-page-for-website-design-or-mobile-app-design-no-image-available-icon-vector.jpg' : 'https://arzanback.webtm.ru/' + item.banner} />
                </Card>
                {item.title}
            </div >
        ),
        'pending': <HeaderSceleton />,
        'idle': <HeaderSceleton />,
        'failed': 'samthing went wrong'

    }
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // плавная прокрутка
        });
    };



    const onChildrenDrawerClose = () => {
        setChildrenDrawer(false);
    };



    const cart_id = Number(localStorage.getItem('cart_id'));
    useEffect(() => {
        dispatch(fetchCartItems({ id: cart_id, cancelToken: source.token }));
    }, []);
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (

        <header className={classes.header}>

            <nav style={!isScrolled ? { boxShadow: "5px 5px 14px 0px rgba(0,0,0,0)", top: '0px' } : { boxShadow: " 5px 5px 14px 0px rgba(66,66,66,0.28)", top: '10px' }}>

                <img style={{ cursor: 'pointer' }} onClick={() => navigate('/')} src={logo} alt="" />

                <Flex style={{ width: '100%' }} gap={16}>
                    <div className={`${classes.categories_inp} ${classes.block1200}`}>
                        <input placeholder='Искать в Arzanall...' onChange={(e) => setSerach(e.target.value)} value={search} type="text" name="" id="" />
                        <button>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M19.1699 18.8984L14.827 14.5555M14.827 14.5555C15.5699 13.8126 16.1591 12.9307 16.5612 11.9601C16.9632 10.9894 17.1702 9.94914 17.1702 8.89855C17.1702 7.84796 16.9632 6.80766 16.5612 5.83704C16.1591 4.86642 15.5699 3.9845 14.827 3.24162C14.0841 2.49874 13.2022 1.90945 12.2315 1.50741C11.2609 1.10537 10.2206 0.898437 9.17004 0.898438C8.11945 0.898438 7.07914 1.10537 6.10853 1.50741C5.13791 1.90945 4.25598 2.49874 3.5131 3.24162C2.01279 4.74193 1.16992 6.77679 1.16992 8.89855C1.16992 11.0203 2.01279 13.0552 3.5131 14.5555C5.01341 16.0558 7.04827 16.8987 9.17004 16.8987C11.2918 16.8987 13.3267 16.0558 14.827 14.5555Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </button>
                    </div>
                    <div
                        className={classes.live}
                        style={{ display: search === '' ? 'none' : 'block' }}
                    >
                        {live.results.map((item) => (
                            <Button
                                key={item.id}
                                onClick={() => {
                                    navigate(`/product/${item.id}`);
                                    setSerach('');
                                }}
                                icon={
                                    <div className={classes.liveItem}>
                                        <img src={item.image} alt="" />
                                    </div>
                                }
                                type="text"
                                style={{
                                    width: '100%',
                                    textAlign: 'left',
                                    height: 'fit-content',
                                    display: 'flex',
                                    alignItems: 'center',
                                    overflow: 'hidden',
                                }}
                            >
                                {item.title}
                            </Button>
                        ))}
                    </div>

                    <Button onClick={() => setMobileOpen(true)} className={classes.block1210} style={{ height: '42px', width: '58px' }} type='primary' icon={<MenuFoldOutlined />}></Button>
                </Flex>
                <ul>
                    {
                        headerItems.map((item, index) =>
                            <li key={index}>
                                <Link to={item.path}>
                                    <Button type="text" icon={<img width={25} src={item.icon} />} block>
                                        {item.label}
                                    </Button>
                                </Link>
                            </li>
                        )
                    }
                </ul>

                <div className={classes.phones}>
                    <a href={`tel:+996 550 03 85 05`}>+996 550 03 85 05</a>
                    <br />
                    <a href={`tel:+996 550 03 45 05`}>+996 550 03 45 05</a>
                    <br />
                    <a href={`tel:  +996 556 00 35 42`}>  +996 556 00 35 42</a>


                </div>

            </nav>

            <div style={{ height: '100px' }}></div>

            <div className={classes.sexilend}>

                <div className={`${classes.categories}`}>
                    <Button onClick={!open ? showDrawer : onClose} className={classes.categories_btn} style={{ color: 'black !important', boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)", background: '#17193f' }} type="primary" icon={!open ? <AppstoreOutlined /> : <CloseOutlined />} >
                        Каталог
                    </Button>

                    <div style={{ boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)" }} className={`${classes.categories_inp}  ${classes.none1200}`}>
                        <input placeholder='Искать в Arzanall...' onChange={(e) => setSerach(e.target.value)} value={search} type="text" name="" id="" />
                        <button>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M19.1699 18.8984L14.827 14.5555M14.827 14.5555C15.5699 13.8126 16.1591 12.9307 16.5612 11.9601C16.9632 10.9894 17.1702 9.94914 17.1702 8.89855C17.1702 7.84796 16.9632 6.80766 16.5612 5.83704C16.1591 4.86642 15.5699 3.9845 14.827 3.24162C14.0841 2.49874 13.2022 1.90945 12.2315 1.50741C11.2609 1.10537 10.2206 0.898437 9.17004 0.898438C8.11945 0.898438 7.07914 1.10537 6.10853 1.50741C5.13791 1.90945 4.25598 2.49874 3.5131 3.24162C2.01279 4.74193 1.16992 6.77679 1.16992 8.89855C1.16992 11.0203 2.01279 13.0552 3.5131 14.5555C5.01341 16.0558 7.04827 16.8987 9.17004 16.8987C11.2918 16.8987 13.3267 16.0558 14.827 14.5555Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </button>
                    </div>
                    <Flex gap={1} className='mobnone'>
                        <Button type='text' style={{ height: '42px', width: '52px', overflow: 'hidden', display: 'flex', alignItems: 'center', justifyContent: 'center' }} href={settings.tiktok} > <img src={whats} style={{ objectFit: 'contain' }} height={40} width={40} alt="" /></Button>
                        <Button type='text' style={{ height: '42px', width: '52px', overflow: 'hidden', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: '23px' }} href={settings.whatsapp} icon={<img src={telega} style={{ objectFit: 'contain' }} height={40} width={30} alt="" />}> </Button>
                        <Button type='text' style={{ height: '42px', width: '52px', overflow: 'hidden', display: 'flex', alignItems: 'center', justifyContent: 'center' }} href={settings.telegram} icon={<img src={inst} style={{ objectFit: 'contain' }} height={30} width={30} alt="" />}> </Button>
                        <Button type='text' style={{ height: '42px', width: '52px', overflow: 'hidden', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: '23px' }} href={settings.instagram} icon={<img src={tiktok} style={{ objectFit: 'contain' }} height={40} width={40} alt="" />}> </Button>

                    </Flex>
                    <Flex gap={10}>
                        <Badge count={cart.cart_items.length}>
                            <Button onClick={() => navigate('/cart')} style={{ height: '42px', boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)" }} type='primary' icon={<ShoppingCartOutlined />} >Корзина</Button>
                        </Badge>
                        <Button style={{ height: '42px', boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)" }} type='primary' icon={<UserOutlined />}> Профиль</Button>
                    </Flex>



                </div>

                <br />
                <br />
                <div className='mainContainer'>
                    <Flex gap={10} className='MainContainer'>
                        {data?.map((item: Categories) =>
                            <li onMouseOver={() => handleMouseEnter(`${item.id}`)} key={item.id}>

                                <Button onClick={() => navigate(`/catalog/${item.id}`)} className={classes.categoryItem} type="text" style={{ backgroundColor: 'rgba(0, 0, 0, 0.06)', boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)" }}>
                                    {item.title}
                                </Button>

                            </li>
                        )}
                    </Flex>
                </div>
            </div>

            <br />

            <div className={classes.test}>
                <div style={!open ? { height: '1px' } : { height: '100vh', opacity: '1' }} className={classes.all}>
                    <Drawer
                        size={'large'}
                        placement={'top'}
                        closable={false}
                        onClose={onClose}
                        open={open}
                        height={'top'}
                        mask={false}
                        className={classes.sidebar_categories}
                        style={{ height: '100vh' }}
                        getContainer={false}

                    >
                        <main className={classes.openCategories}>
                            <aside className={classes.openCategories_sideBar}>
                                <ul className={classes.openCategories_sideBar_desktop}>
                                    {data?.map((item: Categories) =>
                                        <li onMouseOver={() => handleMouseEnter(`${item.id}`)} key={item.id}>

                                            <Button icon={<img width={24} height={24} src={`${item.icon ? item.icon : 'https://static.vecteezy.com/system/resources/thumbnails/022/014/063/small_2x/missing-picture-page-for-website-design-or-mobile-app-design-no-image-available-icon-vector.jpg'}`} />} className={classes.categoryItem} type="text">
                                                {item.title}
                                            </Button>

                                        </li>
                                    )}
                                </ul>
                                <ul className={classes.openCategories_sideBar_mobile}>
                                    {data?.map((item: Categories) =>
                                        <li key={item.id}>

                                            <Button onClick={() => {
                                                handleMouseEnter(`${item.id}`)
                                                showChildrenDrawer()
                                            }} icon={<img width={24} height={24} src={`${item.icon}`} />} className={classes.categoryItem} type="text">
                                                {item.title} <div style={{ width: '100%' }}></div> <RightOutlined />
                                            </Button>

                                        </li>
                                    )}
                                </ul>

                                <Drawer
                                    mask={false}
                                    title="Категории"
                                    width={'100%'}
                                    closable={true}
                                    closeIcon={<LeftOutlined />}
                                    style={{ marginTop: '168px', height: '77vh' }}
                                    placement='left'
                                    onClose={onChildrenDrawerClose}
                                    open={childrenDrawer}
                                >
                                    <div className={classes.openCategories_mai}>
                                        <h1>
                                            {status === 'pending' ? <Skeleton.Input active size={'large'} /> : children[category]?.title}
                                        </h1>
                                        <main className={classes.openCategories_main_items}>
                                            {subCateoryList[status]}
                                        </main>
                                    </div>
                                </Drawer>
                            </aside>
                            <div className={classes.openCategories_main}>
                                <h1>
                                    {status === 'pending' ? <Skeleton.Input active size={'large'} /> : children[category]?.title}
                                </h1>
                                <main className={classes.openCategories_main_items}>
                                    {subCateoryList[status]}
                                </main>
                            </div>

                        </main>
                    </Drawer>
                </div>
            </div>
            <Drawer title="Меню" onClose={() => setMobileOpen(false)} open={mobileOpen}>
                <div style={{ padding: '1rem' }} >
                    <ol>
                        {
                            headerItems.map((item, index) =>
                                <li key={index}>
                                    <Link to={item.path}>
                                        <Button style={{ textAlign: 'left', height: '50px' }} type="text" icon={<img width={25} src={item.icon} />} block>
                                            {item.label}
                                        </Button>
                                    </Link>
                                </li>
                            )
                        }
                    </ol>

                    <Button onClick={() => {
                        setMobileOpen(false)
                        setOpen(prev => !prev)
                        scrollToTop()
                    }} className={classes.categories_btn} style={{ color: 'black !important' }} type="primary" icon={!open ? <AppstoreOutlined /> : <CloseOutlined />} >
                        Каталог
                    </Button>
                    <br />
                    <br />
                    <Button onClick={() => {
                        navigate('/cart')
                        setOpen(false)
                        setMobileOpen(false)
                    }} style={{ height: '42px' }} type='primary' icon={<ShoppingCartOutlined />} >Корзина</Button>
                    <br />
                    <br />
                    <Button style={{ height: '42px' }} type='primary' icon={<UserOutlined />}> Профиль</Button>

                    <br />
                    <Divider></Divider>
                    <a href="tel:+996 550 03 85 05"><b>+996 550 03 85 05</b></a>
                    <Divider></Divider>

                    <a href="tel:+996 550 03 45 05 "> <b>+996 550 03 45 05</b></a>
                    <Divider></Divider>

                    <a href="tel:+996 556 00 35 42">   <b>+996 556 00 35 42</b></a>
                </div>
            </Drawer>
            <FloatButton.Group
                trigger="click"
                type="primary"
                style={{ insetInlineEnd: 84 }}
                icon={<CustomerServiceOutlined />}
            >
                <FloatButton href={settings.tiktok} icon={<img src={whats} style={{ objectFit: 'cover' }} height={40} width={'100%'} alt="" />}> </FloatButton>
                <FloatButton href={settings.whatsapp} icon={<img src={telega} style={{ objectFit: 'cover' }} height={'100%'} width={'100%'} alt="" />}> </FloatButton>
                <FloatButton href={settings.telegram} icon={<img src={inst} style={{ objectFit: 'cover' }} height={'100%'} width={'100%'} alt="" />}> </FloatButton>
                <FloatButton href={settings.instagram} icon={<img src={tiktok} style={{ objectFit: 'cover' }} height={40} width={'100%'} alt="" />}> </FloatButton>
            </FloatButton.Group>
            <div style={{ position: 'fixed', bottom: '48px', right: '30px', zIndex: 99999 }}>


                <Badge count={cart.cart_items.length}>
                    <Button href='/cart' style={{ padding: '10px', borderRadius: '50%', height: "40px", width: '40px', zIndex: 99999 }} type="primary"
                        icon={<ShoppingCartOutlined />}
                    >
                    </Button>
                </Badge>
            </div>
            <Popover placement="topRight" trigger={'click'} title={'ArzanAll.kg'} content={<><BidForm /></>}>

                <FloatButton type='primary' icon={<MessageOutlined />} style={{ insetInlineEnd: 134 }}>
                    <Button style={{ padding: '10px', borderRadius: '50%', height: "40px", width: '40px', zIndex: '982894894893489438994839843894398' }} type="primary"
                        icon={<ShoppingCartOutlined />}
                    >
                    </Button>
                </FloatButton>
            </Popover>


        </header >

    );
};

export default HeaderComponent;
