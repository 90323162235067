import React from "react";
import { Form, Input, Button, message } from "antd";
import axios from "axios";

const { TextArea } = Input;

const BidForm: React.FC = () => {
    const [form] = Form.useForm();

    const onFinish = async (values: { name: string; email: string; message: string }) => {
        try {
            const response = await axios.post("https://arzanback.webtm.ru/api/v1/bid/", values);
            if (response.status === 200 || response.status === 201) {
                message.success("Заявка успешно отправлена!");
                form.resetFields();
            } else {
                throw new Error("Ошибка при отправке данных");
            }
        } catch (error) {
            message.error("Ошибка при отправке. Попробуйте еще раз.");
        }
    };

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            initialValues={{ name: "", email: "", message: "" }}
        >
            <Form.Item
                label="Имя"
                name="name"
                rules={[{ required: true, message: "Пожалуйста, введите ваше имя" }]}
            >
                <Input placeholder="Введите ваше имя" />
            </Form.Item>
            <Form.Item
                label="Email"
                name="email"
                rules={[
                    { required: true, message: "Пожалуйста, введите ваш email" },
                    { type: "email", message: "Введите корректный email" },
                ]}
            >
                <Input placeholder="Введите ваш email" />
            </Form.Item>
            <Form.Item
                label="Сообщение"
                name="message"
                rules={[{ required: true, message: "Пожалуйста, введите сообщение" }]}
            >
                <TextArea rows={4} placeholder="Введите ваше сообщение" />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit">
                    Отправить
                </Button>
            </Form.Item>
        </Form>
    );
};

export default BidForm;
