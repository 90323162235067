import { CancelToken } from 'axios';
import { instance } from './index';
import { CRMNews } from 'store/models/INews';

// Получение списка новостей
const getNewsList = (sourceToken?: CancelToken) =>
    instance.get<CRMNews[]>(`/news/`, { cancelToken: sourceToken });

// Получение новости по ID
const getNewsById = (id: number, sourceToken?: CancelToken) =>
    instance.get<CRMNews>(`/news/${id}/`, { cancelToken: sourceToken });

// Создание новой новости
const createNews = (data: FormData, sourceToken?: CancelToken) =>
    instance.post<CRMNews>(`/news/`, data, { cancelToken: sourceToken });

// Обновление новости по ID
const updateNews = (id: number, data: FormData, sourceToken?: CancelToken) =>
    instance.patch<CRMNews>(`/news/${id}/`, data, { cancelToken: sourceToken });

// Частичное обновление новости по ID
const partialUpdateNews = (id: number, data: FormData, sourceToken?: CancelToken) =>
    instance.patch<CRMNews>(`/news/${id}/`, data, { cancelToken: sourceToken });

// Удаление новости по ID
const deleteNews = (id: number, sourceToken?: CancelToken) =>
    instance.delete(`/news/${id}/`, { cancelToken: sourceToken });

const endpoints = {
    getNewsList,
    getNewsById,
    createNews,
    updateNews,
    partialUpdateNews,
    deleteNews,
};

export default endpoints;
