import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CRMNews } from '../models/INews';
import {
    fetchNewsList,
    fetchNewsById,
    createNews,
    updateNews,
    deleteNews
} from '../reducers/newsReduser';

interface NewsState {
    newsList: CRMNews[];
    currentNews: CRMNews | null;
    loading: boolean;
    error: string | null;
}

const initialState: NewsState = {
    newsList: [],
    currentNews: null,
    loading: false,
    error: null,
};

const newsSlice = createSlice({
    name: 'news',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchNewsList.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchNewsList.fulfilled, (state, action: PayloadAction<CRMNews[]>) => {
                state.loading = false;
                state.newsList = action.payload;
            })
            .addCase(fetchNewsList.rejected, (state, action: PayloadAction<string | undefined>) => {
                state.loading = false;
                state.error = action.payload || 'Failed to fetch news list';
            })
            .addCase(fetchNewsById.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchNewsById.fulfilled, (state, action: PayloadAction<CRMNews>) => {
                state.loading = false;
                state.currentNews = action.payload;
            })
            .addCase(fetchNewsById.rejected, (state, action: PayloadAction<string | undefined>) => {
                state.loading = false;
                state.error = action.payload || 'Failed to fetch news';
            })
            .addCase(createNews.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
       
            .addCase(createNews.rejected, (state, action: PayloadAction<string | undefined>) => {
                state.loading = false;
                state.error = action.payload || 'Failed to create news';
            })
            .addCase(updateNews.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
        
            .addCase(updateNews.rejected, (state, action: PayloadAction<string | undefined>) => {
                state.loading = false;
                state.error = action.payload || 'Failed to update news';
            })
            .addCase(deleteNews.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
      
            .addCase(deleteNews.rejected, (state, action: PayloadAction<string | undefined>) => {
                state.loading = false;
                state.error = action.payload || 'Failed to delete news';
            });
    },
});

export default newsSlice.reducer;
