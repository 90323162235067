import { api } from 'api';
import { Advantages, MainCaruselComponent, Promotion, TopOffer } from 'Components';
import InspirationSection from 'Components/InspirationSection/InspirationSection';
import NewPromotion from 'Components/NewPromo/Promotion';
import OfferSectionMini from 'Components/OfferSectionMini/OfferSectionMini';
import PartnersSection from 'Components/PatrnersSectin/PartnersSection';
import ReviewSection from 'Components/ReviewSection/ReviewSection';
import CategoriesSectiom from 'Components/Service/CategoriesSectiom';
import ServiceComponent from 'Components/Service/ServiceComponent';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { fetchNewsList } from 'store/reducers/newsReduser';
import { fetchProductOfDay } from 'store/reducers/producRedusers';
import { fetchReviews } from 'store/reducers/reviewsReducers';
import classes from "./MainPage.module.scss";
import axios from 'axios';
import { useParams } from 'react-router-dom';

const SectionsContainer = () => {
    const { data } = useAppSelector((state) => state.rewues)
    const { productsDay } = useAppSelector((state) => state.produckt)
    const { token } = useParams()
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(fetchReviews({}))
        dispatch(fetchProductOfDay({}))
    }, [])
    const top_product = productsDay?.top_products.slice(0, 2).map((item, index) => {
        return <OfferSectionMini
            key={index}
            id={item.id}
            category={item.category}
            title={item.title}
            currentPrice={item.price}
            oldPrice={item.old_price}
            discount="8%"
            imageUrl={item.image}
        />
    })

    const defaultSections = [
        { id: '1', component: <MainCaruselComponent /> },
        { id: '2', component: <CategoriesSectiom /> },
        { id: '3', component: <Promotion title="Акции и скидки" getCarts={api.getProductBestSellers} /> },
        { id: '4', component: <ServiceComponent /> },
        { id: '5', component: <NewPromotion title="Хиты продаж" getCarts={api.getPromotionRandomProducts} /> },
        { id: '6', component: <PartnersSection /> },
        { id: '7', component: <TopOffer products_quantity={3} /> },
        { id: '8', component: <Advantages /> },
        { id: '9', component: <InspirationSection /> },
        { id: '10', component: <ReviewSection  /> },
    ];

    const [sections, setSections] = useState(defaultSections);

    // Загружаем порядок секций из API при инициализации
    useEffect(() => {
        axios.get('https://arzanback.webtm.ru/api/v1/settings/')
            .then(response => {
                const savedOrderIds = response.data?.order;
                if (savedOrderIds) {
                    const reorderedSections = savedOrderIds.map(
                        (id: string) => defaultSections.find(section => section.id === id)
                    );
                    setSections(reorderedSections);
                }
            })
            .catch(error => console.error("Ошибка при загрузке порядка секций:", error));
    }, []);

    // Сохраняем новый порядок секций через API
    const saveOrder = (newOrder: { id: string }[]) => {
        const orderIds = newOrder.map(section => section.id); // Формируем массив id секций
        axios.patch('https://arzanback.webtm.ru/api/v1/crm/setting/1/', {
            order: orderIds // Формат для API
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => console.log("Порядок секций сохранен:", response))
            .catch(error => console.error("Ошибка при сохранении порядка секций:", error));
    };

    const onDragEnd = (result: any) => {
        const { source, destination } = result;
        if (!destination) return;

        const reorderedSections = Array.from(sections);
        const [removed] = reorderedSections.splice(source.index, 1);
        reorderedSections.splice(destination.index, 0, removed);

        setSections(reorderedSections);
        saveOrder(reorderedSections);  // Сохраняем порядок после перемещения
    };

    return (
        <div className='mainContainer'>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="sections">
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {sections.map((section, index) => (
                                <Draggable key={section.id} draggableId={section.id} index={index}>
                                    {(provided) => (
                                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                            {section.component}
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    );
};

export default SectionsContainer;