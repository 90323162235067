import React, { useState } from 'react';
import { Form, Input, Button, message, Spin } from 'antd';
import { UserOutlined, PhoneOutlined, EditOutlined } from '@ant-design/icons';
import styles from './OptovikamForm.module.scss';
import axios from 'axios';

interface FormData {
    first_name: string;
    phone: string;
    note: string;
}

const CustomerForm: React.FC = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);

    const onFinish = async (values: FormData) => {
        setLoading(true);
        try {
            // Имитация POST запроса
            await axios.post('https://arzanback.webtm.ru/api/v1/wholesaler/',values)
            message.success('Форма успешно отправлена!');
            form.resetFields();
        } catch (error) {
            message.error('Ошибка при отправке формы. Пожалуйста, попробуйте еще раз.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={styles.formContainer}>
            <Spin spinning={loading}>
                <Form
                    form={form}
                    layout="vertical"
                    name="customer_form"
                    onFinish={onFinish}
                >
                    <Form.Item
                        className={styles.formItem}
                        name="first_name"
                        label="Имя"
                        rules={[{ required: true, message: 'Пожалуйста, введите имя' }]}
                    >
                        <Input
                            className={styles.input}
                            placeholder="Введите имя"
                            prefix={<UserOutlined />}
                        />
                    </Form.Item>

                    <Form.Item
                        className={styles.formItem}
                        name="phone"
                        label="Телефон"
                        rules={[
                            { required: true, message: 'Пожалуйста, введите номер телефона' },
                            { pattern: /^\d+$/, message: 'Пожалуйста, введите корректный номер телефона' },
                        ]}
                    >
                        <Input
                            className={styles.input}
                            placeholder="Введите номер телефона"
                            prefix={<PhoneOutlined />}
                        />
                    </Form.Item>

                    <Form.Item
                        className={styles.formItem}
                        name="note"
                        label="Заметка"
                        rules={[{ required: false }]}
                    >
                        <Input.TextArea
                            className={styles.textArea}
                            placeholder="Добавьте заметку"

                        />
                    </Form.Item>

                    <Form.Item>
                        <Button
                            className={styles.submitButton}
                            type="primary"
                            htmlType="submit"
                            disabled={loading}
                        >
                            {loading ? 'Отправка...' : 'Отправить'}
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>
        </div>
    );
};

export default CustomerForm;
