import { CancelToken } from 'axios';
import { instance } from './index'


//cart
const createCart = (id: number, sourceToken?: CancelToken) =>
    instance.post(
        '/carts/cart/',
        { user: id, },
        { cancelToken: sourceToken }
    );

const addToCart = (cart: number, product: number, quantity: number, product_configuration?: any, sourceToken?: CancelToken) =>
    instance.post(
        '/carts/items/',
        {
            cart: cart,
            product: product,
            quantity: quantity,
            product_configuration: product_configuration
        },
        { cancelToken: sourceToken }
    );


const getOwnCartItems = (id?: number, sourceToken?: CancelToken) =>
    instance.get(`/carts/cart/${id}`, { cancelToken: sourceToken });

const deleteCartItems = (id: number, access_token: string, sourceToken?: CancelToken) =>
    instance.delete(`/carts/cart/${id}/`, {
        headers: {
            Authorization: `Bearer ${access_token}`
        },
        cancelToken: sourceToken
    },);

const deleteCartItem = (id: number, sourceToken?: CancelToken) =>
    instance.delete(`/carts/items/${id}/`, {

        cancelToken: sourceToken
    },);

const updateQuantityCartItem = (id: number, quantity?: number, product_configuration?: any, sourceToken?: CancelToken) =>
    instance.patch(
        `/carts/items/${id}/`,
        {
            quantity: quantity,
            product_configuration: product_configuration
        },
        {

            cancelToken: sourceToken
        }
    );

const updateSelectedCartItem = (id: number, is_selected: boolean, sourceToken?: CancelToken) =>
    instance.patch(
        `/carts/items/${id}/`,
        {
            is_selected: is_selected
        },
        {
            cancelToken: sourceToken
        }
    );

const endpoints = {
    createCart,
    addToCart,
    getOwnCartItems,
    deleteCartItem,
    updateQuantityCartItem,
    updateSelectedCartItem,
    deleteCartItems
};
export default endpoints;
