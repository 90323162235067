import { createSlice } from '@reduxjs/toolkit';
import { addCartItem, addLocalCartItem, deleteCartItem, deleteCartItems, deleteCheckedCartItems, fetchCartItems, loadCartFromLocalStorage, updateCartToLocalStorage, updateQuantityCartItem, updateSelectedCartItem } from 'store/reducers/cartRedusers';
import { CartData } from '../models/CartTypes';
import { getCookie } from 'helpers/cookies';
import { Reviews, ReviewsData } from 'store/models/ReviewTypes';
import { fetchReviews } from 'store/reducers/reviewsReducers';
const is_auth = getCookie('access_token')


interface CartState {
    data: Reviews[];
    status: 'idle' | 'pending' | 'succeeded' | 'failed';
    error: string | null;
    loading: boolean
}

const initialState: CartState = {
    data: [],
    status: 'idle',
    error: null,
    loading: false
};

const rewuesSlice = createSlice({
    name: 'revue',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchReviews.pending, (state) => {
                state.status = 'pending';
                state.loading = true
            })
            .addCase(fetchReviews.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = action.payload
                state.loading = false
            })
            .addCase(fetchReviews.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error ? action.error.message || 'Failed to fetch cart items' : 'Failed to fetch cart items';
                state.loading = false
            })

    },
});
export default rewuesSlice.reducer;
