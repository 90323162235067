import { Advantages } from "Components";
import { CategoryComponent, TopOffer } from "Components";
import classes from "./MainPage.module.scss";
import { FC, useEffect, useState } from "react";
import { MainCaruselComponent, CompanyListComponent } from "Components";
import { Promotion } from '../../Components/index'
import { api } from "api";
import Protected from "routes/Protected/Protected";
import ServiceComponent from "Components/Service/ServiceComponent";
import OfferSectionMini from "Components/OfferSectionMini/OfferSectionMini";
import InspirationSection from "Components/InspirationSection/InspirationSection";
import { Inspiratin } from "data/Inspiration";
import ReviewSection from "Components/ReviewSection/ReviewSection";
import CategoriesSectiom from "Components/Service/CategoriesSectiom";
import { Flex } from "antd";
import { useAppDispatch, useAppSelector } from "store/hook";
import { fetchReviews } from "store/reducers/reviewsReducers";
import { fetchProductOfDay } from "store/reducers/producRedusers";
import { fetchNewsList } from "store/reducers/newsReduser";
import PartnersSection from "Components/PatrnersSectin/PartnersSection";
import ProductShowcase from "Components/NewCardsSection/ProductShowcase";
import NewPromotion from "Components/NewPromo/Promotion";
import axios from "axios";


const data = {
  rating: 4.9,
  totalReviews: 520,
  reviews: [
    {
      username: "Покупатель Arzanall",
      date: "07.06.2024",
      rating: 5,
      comment: "Очень доволен покупкой в этом магазине! Широкий выбор товаров, нашел всё, что нужно для ремонта. Цены порадовали, а доставка была очень быстрой. Всё приехало в целости и сохранности. Рекомендую!",
      avatar: "https://example.com/avatar1.jpg"
    },
    {
      username: "Покупатель Arzanall",
      date: "07.06.2024",
      rating: 5,
      comment: "Заказывал здесь материалы для стройки дома. Остался доволен: качественные товары, приемлемые цены. Консультанты помогли с выбором, а доставка прошла без проблем. Буду обращаться снова!",
      avatar: "https://example.com/avatar2.jpg"
    },
    {
      username: "Покупатель Arzanall",
      date: "07.06.2024",
      rating: 5,
      comment: "Отличный интернет-магазин! Понравился удобный интерфейс сайта и профессиональная консультация по телефону. Все материалы были доставлены вовремя и соответствовали описанию. Спасибо за отличный сервис!",
      avatar: "https://example.com/avatar3.jpg"
    }
  ]
};

const MainPage: FC = () => {
  const { data } = useAppSelector((state) => state.rewues)
  const { productsDay } = useAppSelector((state) => state.produckt)
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(fetchReviews({}))
    dispatch(fetchProductOfDay({}))
  }, [])
  const top_product = productsDay?.top_products.slice(0, 2).map((item, index) => {
    return <OfferSectionMini
      key={index}
      id={item.id}
      category={item.category}
      title={item.title}
      currentPrice={item.price}
      oldPrice={item.old_price}
      discount="8%"
      imageUrl={item.image}
    />
  })

  const defaultSections = [
    { id: '1', component: <MainCaruselComponent /> },
    { id: '2', component: <CategoriesSectiom /> },
    { id: '3', component: <Promotion title="Акции и скидки" getCarts={api.getProductBestSellers} /> },
    { id: '4', component: <ServiceComponent /> },
    { id: '5', component: <NewPromotion title="Хиты продаж" getCarts={api.getPromotionRandomProducts} /> },
    { id: '6', component: <PartnersSection /> },
    { id: '7', component: <TopOffer products_quantity={3} /> },
    { id: '8', component: <Advantages /> },
    { id: '9', component: <InspirationSection /> },
    { id: '10', component: <ReviewSection /> },
  ];
  const [sections, setSections] = useState(defaultSections);
  useEffect(() => {
    axios.get('https://arzanback.webtm.ru/api/v1/settings/')
      .then(response => {
        const savedOrderIds = response.data?.order;
        if (savedOrderIds) {
          const reorderedSections = savedOrderIds.map(
            (id: string) => defaultSections.find(section => section.id === id)
          );
          setSections(reorderedSections);
        }
      })
      .catch(error => console.error("Ошибка при загрузке порядка секций:", error));
  }, []);
  return (
    <div className="mainContainer">

      {sections.map((section, index) => (
        <div style={{ marginBottom: '3rem' }} key={section.id}>
          {section.component}
        </div>
      ))}
    </div >
  );
};

export default MainPage;
