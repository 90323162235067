import React, { useState, useEffect } from "react";
import classes from './OrderPlacing.module.scss';
import { Input, Button, Form, Radio, Checkbox, Modal, message, Select, Tag } from "antd";
import { useAppDispatch, useAppSelector } from "store/hook";
import { fetchCartItems } from "store/reducers/cartRedusers";
import { getCookie } from "helpers/cookies";
import axios from "axios";
import { api } from "api";
import CartTable from "Components/CartTable/CartTable";
import { useNavigate } from "react-router-dom";

const { Option } = Select;

const OrderPlacing: React.FC = () => {
    const dispatch = useAppDispatch();
    const { data } = useAppSelector((state) => state.cart);
    const [modal, contextHolder] = Modal.useModal();
    const [pickup, setPickup] = useState(false);
    const cart_id = Number(localStorage.getItem('cart_id'));
    const user_id = Number(getCookie('user_id'));

    const [options, setOptions] = useState([])
    const navigate = useNavigate()
    useEffect(() => {
        async function sex() {
            try {
                const res = await axios.get('https://arzanback.webtm.ru/api/v1/stock/')
                setOptions(res.data)
            } catch (error) {

            }
        }
        sex()
    }, [])


    useEffect(() => {
        const source = axios.CancelToken.source();
        dispatch(fetchCartItems({ id: cart_id, cancelToken: source.token }));
    }, [dispatch, cart_id]);

    const bilingProduckts: any = [];
    data?.cart_items.forEach((item) => {
        bilingProduckts.push({
            product: item.product.id,
            quantity: item.quantity,
            price: item.product.price,
            product_configuration: item.product_configuration
        });
    });

    const countDown = () => {
        let secondsToGo = 10;
        const instance = modal.success({
            title: 'Ваш заказ успешо принят',
            content: `окно закроеться через ${secondsToGo} .`,
        });
        const timer = setInterval(() => {
            secondsToGo -= 1;
            instance.update({
                content: `окно закроеться через ${secondsToGo}.`,
            });
        }, 1000);

        setTimeout(() => {
            clearInterval(timer);
            instance.destroy();
        }, secondsToGo * 1000);
        const source = axios.CancelToken.source();
        dispatch(fetchCartItems({ id: cart_id, cancelToken: source.token }));
        navigate('/')
    };

    const onFinish = async (values: any) => {
        try {
            await api.orders(
                user_id ? {
                    //@ts-ignore
                    "user_id": Number(user_id),
                    "email": values.email,
                    "first_name": values.username,
                    "last_name": values.surname,
                    "phone": values.number,
                    "billing_receipt_type": values.billing_receipt_type,
                    "country": values.country,
                    "region": values.region,
                    "city": values.city,
                    "street": values.street,
                    "apartment": values.office,
                    "zip_code": values.index,
                    "note": values.note,
                    "billing_products": bilingProduckts,
                    "stock": values.stock,
                    "cart_id": cart_id
                } : {
                    "email": values.email,
                    "first_name": values.username,
                    "last_name": values.surname,
                    "phone": values.number,
                    "billing_receipt_type": values.billing_receipt_type,
                    "country": values.country,
                    "region": values.region,
                    "city": values.city,
                    "street": values.street,
                    "apartment": values.office,
                    "zip_code": values.index,
                    "note": values.note,
                    "billing_products": bilingProduckts,
                    "stock": values.stock,
                    "cart_id": cart_id
                }
            );
            countDown();
        } catch (error: any) {
            message.error('Ошибка при оформлении заказа. Пожалуйста, проверьте данные и попробуйте снова.');
        }
    };

    const handleDeliveryTypeChange = (e: any) => {
        if (e.target.value === "Самовывоз") {
            setPickup(true);
        } else {
            setPickup(false);
        }
    };

    const { TextArea } = Input;

    return (
        <div className="mainContainer">
            <section className={classes.order}>
                <div className={classes.conteiner}>
                    <h2>Оформление заказа</h2>
                    <div className={classes.flexConteiner}>
                        <Form
                            onFinish={onFinish}
                            name="complex-form"
                            initialValues={{
                                billing_receipt_type: "Доставка", // Устанавливаем "Доставка" по умолчанию
                            }}
                            className={classes.left}
                        >
                            <div className={classes.flexForm}>
                                <Form.Item className={classes.item}>
                                    <div className={classes.input}>
                                        <h3 style={{ marginBottom: '32px' }}>Личные данные</h3>
                                        <Form.Item
                                            name="username"
                                            style={{ marginBottom: 0 }}
                                            rules={[{ required: true, message: 'Пожалуйста, введите ваше имя' }]}
                                        >
                                            <Input
                                                className={classes.input1}
                                                placeholder="Имя"
                                                size="large"
                                                suffix={<span style={{ color: 'red' }}>*</span>}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className={classes.input}>
                                        <Form.Item
                                            name="email"
                                            style={{ marginBottom: 0 }}
                                        >
                                            <Input
                                                type="email"
                                                className={classes.input}
                                                placeholder="E-mail"
                                                size="large"
                                            />
                                        </Form.Item>
                                    </div>
                                </Form.Item>

                                <Form.Item className={classes.item}>
                                    <h3 style={{ marginBottom: '32px', opacity: '0' }}>Личные данные</h3>
                                    <div className={classes.input}>
                                        <Form.Item
                                            name="surname"
                                            style={{ marginBottom: 0 }}
                                            rules={[{ required: true, message: 'Пожалуйста, введите вашу фамилию' }]}
                                        >
                                            <Input
                                                className={classes.input}
                                                placeholder="Фамилия"
                                                size="large"
                                                suffix={<span style={{ color: 'red' }}>*</span>}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className={classes.input}>
                                        <Form.Item
                                            name="number"
                                            style={{ marginBottom: 0 }}
                                            rules={[{ required: true, message: 'Пожалуйста, введите ваш телефон' }]}
                                        >
                                            <Input
                                                className={classes.input}
                                                placeholder="Телефон"
                                                size="large"
                                                suffix={<span style={{ color: 'red' }}>*</span>}
                                            />
                                        </Form.Item>
                                    </div>
                                </Form.Item>
                            </div>

                            <h3>Способ доставки</h3>
                            <div className={classes.flexForm}>
                                <div className={classes.item}>
                                    {/* <Tag color="red">обязательно</Tag>
                                <br /> */}
                                    <Form.Item
                                        name="billing_receipt_type"
                                        rules={[{ required: true, message: 'Пожалуйста, выберите способ доставки' }]}
                                    >
                                        <Radio.Group onChange={handleDeliveryTypeChange}>
                                            <Radio className={classes.radio} value="Доставка">Доставка по Адресу</Radio>
                                            <Radio className={classes.radio} value="Самовывоз">Самовывоз из магазина</Radio>
                                        </Radio.Group>
                                    </Form.Item>



                                </div>
                            </div>
                            {pickup && (
                                <Form.Item
                                    style={{ width: '100%' }}
                                    name="stock"
                                    rules={[{ required: true, message: 'Пожалуйста, выберите адрес самовывоза' }]}
                                >
                                    <Select style={{ width: '100%' }} placeholder="Выберите адрес самовывоза" size="large">
                                        {
                                            options?.map((option: any) => <Option value={option.id}>{option.id}{option.title} | {option.address}</Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            )}
                            {!pickup && (
                                <>
                                    <h3>Адрес доставки</h3>
                                    <div className={classes.flexForm}>
                                        <Form.Item className={classes.item}>

                                            <div className={classes.input}>
                                                <Form.Item
                                                    name="city"
                                                    rules={[{ required: true, message: 'Пожалуйста, введите ваш город' }]}
                                                    style={{ marginBottom: 0 }}
                                                >
                                                    <Input
                                                        type="text"
                                                        className={classes.input}
                                                        placeholder="Город"
                                                        size="large"
                                                        suffix={<span style={{ color: 'red' }}>*</span>}
                                                    />
                                                </Form.Item>
                                            </div>
                                            <div className={classes.input}>
                                                <Form.Item
                                                    style={{ marginBottom: 0 }}
                                                    name="office"
                                                    rules={[{ required: true, message: 'Пожалуйста, введите ваш адрес' }]}
                                                >
                                                    <Input
                                                        type="text"
                                                        className={classes.input}
                                                        placeholder="Квартира/Офис"
                                                        size="large"
                                                        suffix={<span style={{ color: 'red' }}>*</span>}
                                                    />
                                                </Form.Item>
                                            </div>
                                        </Form.Item>
                                        <Form.Item className={classes.item}>
                                            <div className={classes.input}>
                                                <Form.Item
                                                    name="region"
                                                    style={{ marginBottom: 0 }}
                                                    rules={[{ required: true, message: 'Пожалуйста, введите ваш регион' }]}
                                                >
                                                    <Input
                                                        className={classes.input}
                                                        placeholder="Край/Область/Регион"
                                                        size="large"
                                                        suffix={<span style={{ color: 'red' }}>*</span>}
                                                    />
                                                </Form.Item>
                                            </div>
                                            <div className={classes.input}>
                                                <Form.Item
                                                    style={{ marginBottom: 0 }}
                                                    name="street"
                                                    rules={[{ required: true, message: 'Пожалуйста, введите вашу улицу и дом' }]}
                                                >
                                                    <Input
                                                        className={classes.input}
                                                        placeholder="Улица/Дом"
                                                        size="large"
                                                        suffix={<span style={{ color: 'red' }}>*</span>}
                                                    />
                                                </Form.Item>
                                            </div>
                                            {/* <div className={classes.input}>
                                            <Form.Item
                                                style={{ marginBottom: 0 }}
                                                name="index"
                                                rules={[{ required: true, message: 'Пожалуйста, введите ваш индекс' }]}
                                            >
                                                <Input
                                                    className={classes.input}
                                                    placeholder="Индекс"
                                                    size="large"
                                                    suffix={<span style={{ color: 'red' }}>*</span>}
                                                />
                                            </Form.Item>
                                        </div> */}
                                        </Form.Item>
                                    </div>
                                </>
                            )}

                            <Form.Item name="note">
                                <TextArea placeholder="Примечание..." className={classes.item} rows={6} />
                            </Form.Item>

                            {/* <h3>Способ оплаты</h3>
                        <Form.Item name="radio-group">
                            <Radio.Group>
                                <Radio value="bankCard">Банковской картой</Radio>
                                <Radio value="PayPal">PayPal</Radio>
                                <Radio value="Visa">Visa/ MasterCard</Radio>
                                <Radio value="GooglePay">Google pay/ Apple pay</Radio>
                            </Radio.Group>
                        </Form.Item>

                        <div className={classes.flexForm}>
                            <Form.Item className={classes.item}>
                                <div className={classes.input}>
                                    <Form.Item
                                        name="usernameCart"
                                        style={{ marginBottom: 0 }}
                                        rules={[{ required: true, message: 'Пожалуйста, введите фамилию и имя на карте' }]}
                                    >
                                        <Input
                                            className={classes.input}
                                            placeholder="Фамилия и имя на карте"
                                            size="large"
                                            suffix={<span style={{ color: 'red' }}>*</span>}
                                        />
                                    </Form.Item>
                                </div>
                                <div className={classes.input}>
                                    <Form.Item name="bankCard" rules={[{ required: true, message: 'Пожалуйста, введите номер карты' }]}>
                                        <Input
                                            type="numberCart"
                                            className={classes.input}
                                            placeholder="Номер банковской карты"
                                            size="large"
                                            suffix={<span style={{ color: 'red' }}>*</span>}
                                        />
                                    </Form.Item>
                                </div>
                            </Form.Item>
                            <Form.Item className={classes.item}>
                                <div className={classes.input}>
                                    <Form.Item
                                        name="idCart"
                                        style={{ marginBottom: 0 }}
                                        rules={[{ required: true, message: 'Пожалуйста, введите срок действия карты' }]}
                                    >
                                        <Input
                                            className={classes.input}
                                            placeholder="ММ/ГГ"
                                            size="large"
                                            suffix={<span style={{ color: 'red' }}>*</span>}
                                        />
                                    </Form.Item>
                                </div>
                                <div className={classes.input}>
                                    <Form.Item
                                        name="CVC/CVV"
                                        rules={[{ required: true, message: 'Пожалуйста, введите CVC/CVV код' }]}
                                    >
                                        <Input
                                            className={classes.input}
                                            placeholder="CVC/CVV"
                                            size="large"
                                            suffix={<span style={{ color: 'red' }}>*</span>}
                                        />
                                    </Form.Item>
                                </div>
                            </Form.Item>
                        </div> */}

                            <div className={classes.agreement}>
                                <Form.Item
                                    style={{ marginBottom: '0' }}
                                    name="agreement"
                                    valuePropName="checked"
                                    rules={[
                                        {
                                            validator: (_, value) =>
                                                value ? Promise.resolve() : Promise.reject(new Error('Необходимо принять соглашение')),
                                        },
                                    ]}
                                >
                                    <Checkbox>
                                        Оформляя заказ, вы принимаете условия <a href="#/">Пользовательских соглашений</a> и даете согласие на обработку персональных данных согласно <a href="#/">Политике конфиденциальности.</a>
                                    </Checkbox>
                                </Form.Item>
                            </div>

                            <Form.Item colon={false}>
                                <Button className={classes.button} type="primary" htmlType="submit" block>
                                    Оформить
                                </Button>
                            </Form.Item>
                        </Form>

                        <div className={classes.right}>
                            <h3>Ваш заказ</h3>
                            <CartTable data={data.cart_items} />
                            {/* {data.cart_items.map((item) => (
                            <div className={classes.flexConteinerr}>
                                <div className={classes.imgProduct}>
                                    <img width={250} className={classes.sex} src={item.product.image} alt="" />
                                </div>
                                <div className={classes.title}>
                                    <br /><br />
                                    <h3>{item.product.title}</h3>
                                    <span>Код товара: {item.product.product_code}</span>
                                    <p>Цвет товара: Цвет товара</p>
                                    <p>Количество: {item.quantity}</p>
                                    <div className={classes.price}>
                                        <h2>{item.product.price} c</h2>
                                    </div>
                                </div>
                            </div>
                        ))} */}
                        </div>
                    </div>
                </div>
                {contextHolder}
            </section>
        </div>
    );
}

export default OrderPlacing;
