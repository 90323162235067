import React, { useEffect } from 'react';
import classes from './TopOffer.module.scss';
import { CaretRightOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from 'store/hook';
import axios from 'axios';
import { fetchProductOfDay } from 'store/reducers/producRedusers';
import { products } from 'data/TopOfer/TopOfer';
import { Card, Flex, Tag } from 'antd';
import { Link, useNavigate } from 'react-router-dom';

interface TopOfferProps {
  products_quantity: number;
}

const getDynamicClass = (quantityOfBlocks: number) => {
  if (quantityOfBlocks >= 1 && quantityOfBlocks <= 4) {
    return `x${quantityOfBlocks}`;
  }
  return 'default';
};

const getDiscount = (newPrice: number, oldPrice: number) => {
  const parsedNewPrice = parseFloat(newPrice?.toFixed(2));
  const parsedOldPrice = parseFloat(oldPrice?.toFixed(2));

  return 100 - (parsedNewPrice * 100 / parsedOldPrice);
}


const TopOffer: React.FC<TopOfferProps> = ({ products_quantity }) => {
  const dispatch = useAppDispatch();
  const { productsDay } = useAppSelector((state) => state.produckt)
  const dynamicClass = getDynamicClass(products_quantity);
  const navigate = useNavigate()


  useEffect(() => {
    const source = axios.CancelToken.source();
    dispatch(fetchProductOfDay({ cancelToken: source.token }))
  }, []);



  return (
    <div className={`${classes.topOffer} ${classes[dynamicClass]} ${classes.parent}`}>
      {productsDay?.top_products.slice(0, products_quantity).map((product, index) => (
        <div key={index} className={`${classes.topOffer_Block} ${classes[dynamicClass]} ${classes.div} ${classes[`div${index + 1}`]}`}>
          <div className={classes.sex}>
            <div className={classes.sex_flex}>
              {
                product.old_price && <div onClick={() => navigate(`/product/${product.id}`)} className={classes.topOffer_Block_Up_Discount} >
                  Скидка - {getDiscount(product.price, product?.old_price)?.toFixed(0)}%
                </div>
              }
              <h1>{product.title}</h1>
              <div onClick={() => navigate(`/product/${product.id}`)}>     <h2>  {`${product.price} ${product.currency}`}</h2>
                <h3 onClick={() => navigate(`/product/${product.id}`)}>    {`${product.old_price ? ` ${product.old_price} ${product.currency}` : ''}`}</h3></div>
              <Link to={`/catalog/${product.category}`}>Перейти в каталог</Link>
            </div>
            <div onClick={() => navigate(`/product/${product.id}`)} className={classes.sex_image} style={{ overflow: 'hidden', borderRadius: '10px' }}>
              <img src={product.image} alt={product.title} />            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TopOffer;
