import home from '../../assets/icon/HeaderIcons/Home.svg'
import contact from '../../assets/icon/HeaderIcons/Contact.svg'
import delivary from '../../assets/icon/HeaderIcons/Delivary.svg'
import Optom from '../../assets/icon/HeaderIcons/Optom.svg'
import sex from '../../assets/flat.svg'
import PriceList from '../../assets/icon/HeaderIcons/PriceList.svg'




export const headerItems = [
    {
        path: '/aboutus',
        label: 'О компании',
        icon: home
    },
    {
        path: '/contact',
        label: 'Контакты',
        icon: contact
    }
    ,
    {
        path: '/news',
        label: 'Новости',
        icon: sex
    }
    ,
    {
        path: '/optovikam',
        label: 'Оптовикам',
        icon: Optom
    }
    // ,
    // {
    //     path: '/',
    //     label: 'Прайст лист',
    //     icon: PriceList
    // }
]