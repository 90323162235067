

import { HeaderComponent, SubWithNews } from "Components";
import FooterComponent from "Components/FooterComponent/FooterComponent";
import { Layout } from "antd";
import axios from "axios";
import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
export default function Main() {

  const createCart = async () => {
    try {
      const response = await axios.post('https://arzanback.webtm.ru/api/v1/carts/cart/', {
        session_key: 'sex',
      });

      if (response.data && response.data.id && response.data.session_key) {
        // Save cart_id and session_key in localStorage
        localStorage.setItem('cart_id', response.data.id.toString());
        localStorage.setItem('session_key', response.data.session_key);
      } else {
        console.error('Unexpected response format:', response.data);
      }
    } catch (error) {
      console.error('Error creating cart:', error);
    }
  };

  useEffect(() => {
    if (!localStorage.getItem('cart_id') &&
      !localStorage.getItem('session_key')) {
      createCart()
    }
  }, [])
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Плавная прокрутка
    });
  }, [pathname]);
  return (
    <Layout style={{ overflow: "hidden" }}>
      <Layout>
        <HeaderComponent />
        <Outlet />
        <br />
        <br />
        <SubWithNews />
        <br />
        <br />
        <FooterComponent />

      </Layout>
    </Layout>
  );
}
