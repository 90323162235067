import { createAsyncThunk } from '@reduxjs/toolkit';
import { CancelToken } from 'axios';
import { api } from '../../api';
import { CRMNews } from '../models/INews';

export const fetchNewsList = createAsyncThunk<CRMNews[], { cancelToken?: CancelToken }, { rejectValue: string }>(
    'news/fetchNewsList',
    async ({ cancelToken }, { rejectWithValue }) => {
        try {
            const response = await api.getNewsList(cancelToken);
            return response.data;
        } catch (error) {
            return rejectWithValue('Failed to fetch news list');
        }
    }
);

export const fetchNewsById = createAsyncThunk<CRMNews, { id: number, cancelToken?: CancelToken }, { rejectValue: string }>(
    'news/fetchNewsById',
    async ({ id, cancelToken }, { rejectWithValue }) => {
        try {
            const response = await api.getNewsById(id, cancelToken);
            return response.data;
        } catch (error) {
            return rejectWithValue('Failed to fetch news');
        }
    }
);

export const createNews = createAsyncThunk<CRMNews, { data: FormData, cancelToken?: CancelToken }, { rejectValue: string }>(
    'news/createNews',
    async ({ data, cancelToken }, { rejectWithValue }) => {
        try {
            const response = await api.createNews(data, cancelToken);
            return response.data;
        } catch (error) {
            return rejectWithValue('Failed to create news');
        }
    }
);

export const updateNews = createAsyncThunk<CRMNews, { id: number, data: FormData, cancelToken?: CancelToken }, { rejectValue: string }>(
    'news/updateNews',
    async ({ id, data, cancelToken }, { rejectWithValue }) => {
        try {
            const response = await api.updateNews(id, data, cancelToken);
            return response.data;
        } catch (error) {
            return rejectWithValue('Failed to update news');
        }
    }
);

export const deleteNews = createAsyncThunk<void, { id: number, cancelToken?: CancelToken }, { rejectValue: string }>(
    'news/deleteNews',
    async ({ id, cancelToken }, { rejectWithValue }) => {
        try {
            await api.deleteNews(id, cancelToken);
        } catch (error) {
            return rejectWithValue('Failed to delete news');
        }
    }
);
