import React, { useEffect, useState } from "react";
import { Card, Row, Col, Button, Carousel, Modal } from "antd";
import { CRMNews } from "store/models/INews";
import { fetchNewsList } from "store/reducers/newsReduser";
import { useAppDispatch, useAppSelector } from "store/hook";



const InspirationSection: React.FC = () => {
    const { newsList: sections } = useAppSelector((state) => state.news)
    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(fetchNewsList({}))
    }, [])
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedSection, setSelectedSection] = useState<CRMNews | null>(null);

    const showModal = (section: CRMNews) => {
        setSelectedSection(section);
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <div>
            <h2>Новости Arzanall</h2>
            <div className="desktop-view">

                <Row gutter={[16, 16]}>
                    {sections.slice(0, 4).map((section, index) => (
                        <Col
                            key={index}
                            xs={24}
                            sm={24}
                            md={12}
                            lg={index === 0 ? 24 : 8}
                            xl={index === 0 ? 24 : 8}
                        >
                            <Card
                                className="cardofHouses"
                                color="white"
                                style={{ background: `url(${section.image})center/cover`, height: '420px' }}
                                bodyStyle={{ padding: "16px" }}
                            >
                                <Card.Meta
                                    title={section.title}
                                    description={section.description}
                                />
                                <Button
                                    type="primary"
                                    style={{ marginTop: "10px" }}
                                    onClick={() => showModal(section)}
                                >
                                    Посмотреть
                                </Button>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </div>
            <div className="mobile-view">
                <Carousel slidesToShow={2}>
                    {sections.map((section, index) => (
                        <div className="sliderItem" key={index}>
                            <Card
                                className="cardofHouses"
                                color="white"
                                style={{ background: `url(${section.image})center/cover`, height: '420px' }}
                                bodyStyle={{ padding: "16px" }}
                            >
                                <Card.Meta
                                    title={section.title}
                                    description={section.description}
                                />
                                <Button
                                    type="primary"
                                    style={{ marginTop: "10px" }}
                                    onClick={() => showModal(section)}
                                >
                                    Посмотреть
                                </Button>
                            </Card>
                        </div>
                    ))}
                </Carousel>
            </div>

            <Modal
                title={selectedSection?.title}
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key="ok" type="primary" onClick={handleOk}>
                        ОК
                    </Button>,
                ]}
            >
                <img src={selectedSection?.image} alt={selectedSection?.title} style={{ width: '100%', marginBottom: '20px' }} />
                <p>{selectedSection?.description}</p>
                {/* <p>Дополнительное описание: {selectedSection?.additionalDescription}</p> */}
            </Modal>
        </div>
    );
};

export default InspirationSection;
