import React, { useState, useEffect } from 'react';
import classes from './CartItemComponent.module.scss';
import { Flex, Checkbox, Image, Button, Drawer } from 'antd';
import type { CheckboxProps } from 'antd';
import { CloseOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { CartItem } from 'store/models/CartTypes';
import { deleteCartItem, fetchCartItems, updateQuantityCartItem } from 'store/reducers/cartRedusers';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { getFavoriteProducts } from 'store/reducers/favoritesReducers';
import axios from 'axios';
import { fetchProductById } from 'store/reducers/producRedusers';
import { api } from 'api';
import { useNavigate } from 'react-router-dom';

type Props = {
    cart_item: CartItem,
    isSelected: boolean,
    onSelect: (isSelected: boolean) => void,
};

const CartItemComponent: React.FC<Props> = ({ cart_item, isSelected, onSelect }: Props) => {
    const dispatch = useAppDispatch();
    const [open, setOpen] = useState(false);
    const [count, setCount] = useState(cart_item.quantity);
    const [selectedConfigs2, setSelectedConfigs2] = useState<{ [key: string]: string }>({});
    const { selectedProduct } = useAppSelector((state) => state.produckt);

    useEffect(() => {
        const initialConfigs: { [key: string]: string } = {};
        cart_item.product_configuration_details.forEach((config: any) => {
            initialConfigs[config.configurator_key] = config.selected_value;
        });
        setSelectedConfigs2(initialConfigs);
        const source = axios.CancelToken.source();
        return () => source.cancel('Operation canceled by the user.');
    }, [dispatch, cart_item.product_configuration_details]);

    useEffect(() => {
        dispatch(updateQuantityCartItem({ id: cart_item.id, quantity: count }));
    }, [count, dispatch, cart_item.id]);

    const onChange: CheckboxProps['onChange'] = (e) => {
        onSelect(e.target.checked);
    };

    const inp_change = (add: boolean) => {
        setCount((prevCount) => add ? prevCount + 1 : Math.max(1, prevCount - 1));
    };

    const delete_item = () => {
        dispatch(deleteCartItem({ id: cart_item.id }));
    };

    const showDrawer = () => {
        setOpen(true);
        onConfig(cart_item.product.id);
    };

    const onClose = () => {
        setOpen(false);
    };

    function onConfig(id: any) {
        const source = axios.CancelToken.source();
        dispatch(fetchProductById({ id: Number(id), cancelToken: source.token }));
    }

    const onChange2 = (e: any, key: string) => {
        setSelectedConfigs2((prevConfigs) => {
            const newValue = prevConfigs[key] === e.target.value ? null : e.target.value;

            const newConfigs = { ...prevConfigs };
            if (newValue === null) {
                delete newConfigs[key];
            } else {
                newConfigs[key] = newValue;
            }

            updateCartItemConfig(newConfigs);

            return newConfigs;
        });
    };

    const updateCartItemConfig = async (product_configuration: { [key: string]: string }) => {
        try {
            await api.updateQuantityCartItem(cart_item.id, count, product_configuration).then(() => {
                const cart_id = Number(localStorage.getItem('cart_id'));
                dispatch(fetchCartItems({ id: cart_id }));
            });
        } catch (error) {
            console.error("Failed to update cart item:", error);
        }
    };
    const navigate = useNavigate()


    return (
        <div className={classes.cart_item}>
            <div className={classes.cart_item_image}>
                <Checkbox
                    className={classes.cart_item_image_checkbox}
                    onChange={onChange}
                    checked={isSelected}
                />
                <Image onClick={() => navigate(`/product/${cart_item.product.id}`)} src={cart_item?.product.image} />
            </div>
            <div onClick={() => navigate(`/product/${cart_item.product.id}`)} className={classes.cart_item_desc}>
                <h1>{cart_item.product.title}</h1>
                <p>Код товара: {cart_item.product.product_code}</p>
                <p className={classes.category}>
                    {cart_item.product.product_attributes ?
                        cart_item?.product?.product_attributes?.map((e) => (
                            <span key={e.key}>
                                <u>{e.key}</u>: {e.value} <br />
                            </span>
                        )) : 'Загрузка...'
                    }
                </p>

            </div>
            <div>
                <br />
                <h1 className={classes.price}>{cart_item?.total_price} c</h1>
                <Flex gap={5} align='center' className={classes.count}>
                    <Button type='primary' onClick={() => inp_change(false)}>-</Button>
                    <input min={1} value={count} onChange={(e) => setCount(Number(e.target.value))} />
                    <Button onClick={() => inp_change(true)} type='primary'>+</Button>
                </Flex>
                <br />
                <Button onClick={showDrawer}>Конфигурация</Button>
                <br />
                <br />

                {cart_item?.product_configuration_details?.map((item: any) => (
                    <div key={item.configurator_key}>
                        <Flex>
                            <p style={{ marginRight: '5px' }}>{item.configurator_name} </p>
                            <b> {item.selected_value}</b>
                        </Flex>
                    </div>
                ))}
            </div>
            <Flex align='baseline' gap={16}>
                <Flex gap={20} className={classes.spans}>
                    <CloseOutlined onClick={delete_item}></CloseOutlined>
                </Flex>
            </Flex>
            <Drawer title="Конфигурация" onClose={onClose} open={open}>
                {selectedProduct?.product_configurator.length ?
                    selectedProduct?.product_configurator?.map((el) => (
                        <div key={el.id} className={classes.config_block}>
                            <p className={classes.config_title}>
                                <b>{el.key}</b>
                                <span className={classes.config_value}></span>
                            </p>
                            <div className={classes.config_row}>
                                {el?.values?.map((vl) => (
                                    <Button
                                        key={vl.title}
                                        type={selectedConfigs2[el.configurator_key] === vl.title ? "primary" : "default"}
                                        className={classes.config_button}
                                        onClick={() => onChange2({ target: { value: vl.title } }, el.configurator_key)}
                                    >
                                        {vl.title}
                                    </Button>
                                ))}
                                <br />
                                <br />
                            </div>
                        </div>
                    )) : ''
                }
            </Drawer>
        </div>
    );
};

export default CartItemComponent;
