import React, { useState, useEffect } from 'react';
import { Carousel } from 'antd';
import axios from 'axios';

const PartnersSection = () => {
    const [partners, setPartners] = useState([]);

    useEffect(() => {
        axios.get('https://arzanback.webtm.ru/api/v1/partners/')
            .then(response => {
                setPartners(response.data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    return (
        <Carousel
            autoplay
            dots={false}
            autoplaySpeed={2000}
            slidesToShow={4}
            responsive={[
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
            ]}
        >
            {partners?.map((partner: any, index) => (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} key={partner.id}>
                    <img
                        src={partner.image}
                        alt={partner.title}
                        style={{ width: '100px', margin: '0 auto', height: 'auto' }}
                    />
                </div>
            ))}
        </Carousel>
    );
};

export default PartnersSection;
