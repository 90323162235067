import main from '../../assets/icon/ServiceIcons/Group 416.png';
import constructionCrane from '../../assets/icon/ServiceIcons/56_px__2_.svg.svg';
import projectManagement from '../../assets/icon/ServiceIcons/profyservices_56pix.svg.svg';
import workerHelmet from '../../assets/icon/ServiceIcons/executors_56pix.svg fill.svg';
import discountTag from '../../assets/icon/ServiceIcons/loyalty_56pix.svg.svg';
import toolRent from '../../assets/icon/ServiceIcons/calculator_76pix__1_.svg.svg';
import dreamInterior from '../../assets/icon/ServiceIcons/interior_76pix__1_.svg fill.svg';
import costCalculation from '../../assets/icon/ServiceIcons/calculator_76pix__1_.svg.svg';


interface Section {
    title: string;
    description: string;
    icon: string;
}

export const serviceData: Section[] = [
    {
        title: "Помощь в выборе материалов",
        description: "Подберем качественные пиломатериалы, шифер и цемент для вашего проекта.",
        icon: main
    },
    {
        title: "Услуги для строительных компаний",
        description: "Комплексные решения для строительных проектов любой сложности.",
        icon: constructionCrane
    },
    {
        title: "Для профессионалов",
        description: "Поставка строительных материалов для мастеров и прорабов.",
        icon: projectManagement
    },
    {
        title: "Поиск исполнителей",
        description: "Найдем квалифицированных строителей и специалистов для вашего проекта.",
        icon: workerHelmet
    },
    {
        title: "Программа лояльности",
        description: "Скидки и бонусы для постоянных заказчиков строительных материалов.",
        icon: discountTag
    },
    {
        title: "Аренда инструмента",
        description: "Предоставляем инструменты для выполнения строительных работ.",
        icon: toolRent
    },
    {
        title: "Визуализация интерьера мечты",
        description: "Быстрый расчет материалов и услуг для вашего строительства.",
        icon: dreamInterior
    },
    {
        title: "Расчет стоимости ремонта",
        description: "Поможем быстро посчитать материалы и работы",
        icon: costCalculation
    }
];
