import React from 'react';
import { Row, Col, Card, Flex } from 'antd';
import styles from './Contact.module.scss';
import inst from '../../assets/Mask group-1.png'
import telega from '../../assets/Mask group.png'
import whats from '../../assets/socials-1.png'
import tiktok from '../../assets/socials.png'
import { useAppSelector } from 'store/hook';
import PartnersSection from 'Components/PatrnersSectin/PartnersSection';

const ContactSection: React.FC = () => {
    const { settings } = useAppSelector((state) => state.window)

    return (
        <div className='mainContainer contact'>
            <Row gutter={[16, 16]} justify="center" className={styles.infoSection}>
                <Col xs={24} sm={12} md={6}>
                    <Card className={styles.card}>
                        <h3>Наши адреса</h3>
                        <p>Бишкек, Садыгалиева, 6г/1</p>
                        <p>Бишкек, Алма-Атинская улица, 751/10</p>
                    </Card>
                </Col>
                <Col xs={24} sm={12} md={6}>
                    <Card className={styles.card}>
                        <h3>Наш Email</h3>
                        <a href='arzanallkg@gmail.com'>arzanallkg@gmail.com</a>
                    </Card>
                </Col>
                <Col xs={24} sm={12} md={6}>
                    <Card className={styles.card}>
                        <h3>Наши контакты</h3>
                        <a href={`tel:+996 550 03 85 05`}>+996 550 03 85 05</a>
                        <br />
                        <a href={`tel:+996 550 03 45 05`}>+996 550 03 45 05</a>
                        <br />
                        <a href={`tel:  +996 556 00 35 42`}>  +996 556 00 35 42</a>

                    </Card>
                </Col>
                <Col xs={24} sm={12} md={6}>
                    <Card className={styles.card}>
                        <h3>Мы в социальных сетях</h3>
                        <Flex gap={19} align='center'>
                            <a href={settings.tiktok}> <li><img src={whats} style={{ objectFit: 'cover' }} height={50} width={30} alt="" /></li></a>
                            <a href={settings.whatsapp}>     <li><img src={telega} width={30} alt="" /></li></a>
                            <a href={settings.telegram}>     <li><img src={inst} width={30} alt="" /></li></a>
                            <a href={settings.instagram}>       <li><img style={{ objectFit: 'cover' }} height={50} src={tiktok} width={30} alt="" /></li></a>
                        </Flex>
                    </Card>
                </Col>
            </Row>
            <br /><br />

            <div className={styles.mapSection}>
                <iframe width={'100%'} height={500} src="https://customiframe.vercel.app/test.html" style={{ border: "0", borderRadius: '10px' }} ></iframe>

            </div>
            <div className="mainContainer">

                <br /><br /><br /><br />
                <PartnersSection />
            </div>
        </div>
    );
};

export default ContactSection;
